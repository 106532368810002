import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import searchUIClient from "@/apis/searchUIClient";
import wifiCellularMismatchClient from "@/apis/wifiCellularMismatchClient";
import useDeleteDialog from "@/cleanup/widgets/dialog/useDeleteDialog";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntText from "@/components/ReactBootstrap/SntText";
import { useOrg } from "@/contexts/OrgContext";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import ActionToolEnum from "../../containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "../../containers/AdminLayout/Toolbar/ActionToolType";

const PAGE_NAME = "PRIVATE_CELLULAR_TOWER";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "PRIVATE_CELLULAR_TOWER",
  filtersVisibleAtInit: ["cellId", "createdAt"],
  optionsSettings: {},
};

const PrivateCellularTower = ({ reload }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;
  const { orgId } = useOrg();

  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(
    () => [
      {
        key: "cellId",
        title: language.cell_id,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "cellularInfo",
        title: language.cellular_tower_info,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          var info =
            "mcc: " +
            (full.mcc || language.unknown_key) +
            ", mnc: " +
            (full.net || language.unknown_key) +
            ", area: " +
            (full.area || language.unknown_key) +
            ", cell: " +
            (full.cellId || language.unknown_key);
          return <div>{info}</div>;
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.mcc) {
            text += " - " + data.mcc;
          }
          if (data.net) {
            text += " - " + data.net;
          }
          if (data.area) {
            text += " - " + data.area;
          }
          if (data.cellId) {
            text += " - " + data.cellId;
          }
          return [text];
        },
      },
      {
        key: "cellularLocation",
        title: language.cellular_tower_location,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.cellLat && full.cellLng ? (
            <>
              <div className="d-flex">
                latitude: {full.cellLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.cellLat +
                    "," +
                    full.cellLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.cellLng}</div>
            </>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.cellLat != undefined && data.cellLat != null) {
            text += data.cellLat + " - ";
          }
          if (data.cellLng != undefined && data.cellLng != null) {
            text += data.cellLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.cellLat != undefined && data.cellLat != null) {
            text += data.cellLat + " - ";
          }
          if (data.cellLng != undefined && data.cellLng != null) {
            text += data.cellLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "actualLocation",
        title: language.actual_location,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.actualLat && full.actualLng ? (
            <>
              <div className="d-flex">
                latitude: {full.actualLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.actualLat +
                    "," +
                    full.actualLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.actualLng}</div>
            </>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.actualLat != undefined && data.actualLat != null) {
            text += data.actualLat + " - ";
          }
          if (data.actualLng != undefined && data.actualLng != null) {
            text += data.actualLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.actualLat != undefined && data.actualLat != null) {
            text += data.actualLat + " - ";
          }
          if (data.actualLng != undefined && data.actualLng != null) {
            text += data.actualLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const defaultColumnInit = useMemo(
    () => ["cellId", "cellularLocation", "actualLocation", "createdAt"],
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          var full = cell.row.original;
          return (
            <div>
              {permissionsMap["CELL_TOWER_TROUBLESHOOTING_MANAGE"] && (
                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      params.selectedOrgId = orgId || loginInfo.organisation.id;

      table.current.setLoading(true);
      searchUIClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      wifiCellularMismatchClient
        .deletePrivateCellularTower(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.resetDefault();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />

      <AdminLayout
        isShowHeader={false}
        browserTabTitle={language.private_cellular_towers}
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        totalLabel={language.items_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default PrivateCellularTower;
