import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import organizationClient from "../../apis/organizationClient";
import SntPanel from "../../components/Panel/SntPanel";
import { FormFullScreenLayout } from "../../components/ReactBootstrap/FormValidation";
import InputText from "../../components/ReactBootstrap/FormValidation/InputText";
import ItemSelector from "../../components/ReactBootstrap/FormValidation/ItemSelector";
import LoaderSpinner from "../../components/ReactBootstrap/LoaderSpinner/LoaderSpinner";
import SntDialog from "../../components/SntDialog/SntDialog";
import SntInfoPanel from "../../components/SntInfo/SntInfoPanel";
import { useOrg } from "@/contexts/OrgContext.js";

const SSODomainVerification = ({ reload }) => {
  const { orgId } = useOrg();
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;
  const language = useSelector((state) => state.language);
  const [loading, setLoading] = useState(true);

  const selectableVerificationMethods = [
    { label: language.html_key, value: "HTML_VERIFIED" },
  ];

  const domainVerificationValidationSchema = Yup.object().shape({
    ssoDomain: Yup.string().required(language.valid_required_key).nullable(),
  });

  const [isShowSuccessResponse, setShowSuccessResponse] = useState(false);
  const [isShowErrorResponse, setShowErrorResponse] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [domainVerification, setDomainVerification] = useState({
    ssoDomainVerified: false,
    selectedVerificationMethod: "HTML_VERIFIED",
    ssoDomain: "",
  });

  useEffect(() => {
    organizationClient.findOne({ id: orgId }).then(({ data }) => {
      updateFormData(data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, orgId]);

  function updateFormData(data) {
    let existingVerificationDomain = { ...domainVerification };

    existingVerificationDomain.ssoDomain = data.ssoDomain || "";
    existingVerificationDomain.ssoDomainVerified = data.ssoDomainVerified;
    existingVerificationDomain.verificationMethod = data.verificationMethod;
    existingVerificationDomain.selectedVerificationMethod = {
      value: "HTML_VERIFIED",
    };
    setDomainVerification(existingVerificationDomain);
  }

  function onVerificationDomainSubmit(values) {
    organizationClient
      .verifyDomain(orgId, values.ssoDomain)
      .then((data) => {
        setShowSuccessResponse(true);
        setDialogMessage(language.verify_domain_successfully_key);
      })
      .catch((error) => {
        setShowErrorResponse(true);
        setDialogMessage(error.response.data.message);
      });
  }

  function onForceVerificationDomainSubmit(values) {
    domainVerificationValidationSchema.isValid(values).then(function (valid) {
      if (valid) {
        organizationClient
          .forceVerifyDomain(orgId, values.ssoDomain)
          .then((data) => {
            setDomainVerification({
              ...domainVerification,
              ssoDomain: values.ssoDomain,
              ssoDomainVerified: true,
              verificationMethod: "FORCE_VERIFIED",
            });
            setShowSuccessResponse(true);
            setDialogMessage(language.verify_domain_successfully_key);
          })
          .catch((error) => {
            setShowErrorResponse(true);
            setDialogMessage(error.response.data.message);
          });
      }
    });
  }

  const canEdit = () => {
    return permissionsMap["ORGANIZATION_MANAGE"];
  };

  return (
    <div>
      <LoaderSpinner loading={loading} />
      <SntDialog
        isShow={isShowSuccessResponse}
        onSave={() => {
          setShowSuccessResponse(false);
          setShowErrorResponse(false);
          setDialogMessage(null);
        }}
        title={language.notifications_key}
        isShowCloseButton={false}
        isShowSaveButton={true}
        saveTxt={language.ok_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>
      <SntDialog
        isShow={isShowErrorResponse}
        onSave={() => {
          setShowErrorResponse(false);
          setShowSuccessResponse(false);
          setDialogMessage(null);
        }}
        title={language.error_key}
        isShowSaveButton={true}
        isShowCloseButton={false}
        btnSaveClassName="danger"
        saveTxt={language.ok_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>

      <Formik
        key="verification"
        initialValues={domainVerification}
        enableReinitialize={true}
        validationSchema={domainVerificationValidationSchema}
        onSubmit={onVerificationDomainSubmit}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form>
              <SntPanel title={language.domain_verification_key}>
                <FormFullScreenLayout
                  label={language.domain_name_key}
                  isRequired={true}
                  rightRender={() => (
                    <>
                      <InputText
                        key="ssoDomain"
                        name="ssoDomain"
                        placeholder="yourwebsite.com"
                        disabled={!canEdit()}
                      />
                    </>
                  )}
                />

                <FormFullScreenLayout
                  label={language.verification_status_key}
                  rightRender={() => (
                    <>
                      {values.ssoDomain ? (
                        [
                          values.ssoDomainVerified &&
                          values.ssoDomain === domainVerification.ssoDomain ? (
                            <b
                              key={0}
                              className="verified"
                              style={{
                                color: "green",
                                paddingRight: "inherit",
                              }}
                            >
                              {language.verified_upper_key}
                            </b>
                          ) : (
                            <b
                              key={1}
                              className="text-warning"
                              style={{ paddingRight: "inherit" }}
                            >
                              {language.not_verified_key}
                            </b>
                          ),
                        ]
                      ) : (
                        <b
                          key={2}
                          className="none"
                          style={{ color: "gray", paddingRight: "inherit" }}
                        >
                          {language.none_key}
                        </b>
                      )}
                      {permissionsMap["SYSTEM_ADMIN_MANAGE"] && (
                        <a
                          href="  "
                          disabled={
                            values.ssoDomain &&
                            values.ssoDomainVerified &&
                            values.ssoDomain === domainVerification.ssoDomain
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            onForceVerificationDomainSubmit(values);
                          }}
                        >
                          {language.force_verify_key}
                        </a>
                      )}
                    </>
                  )}
                />

                {values.ssoDomain &&
                  values.ssoDomainVerified &&
                  values.ssoDomain === domainVerification.ssoDomain && (
                    <FormFullScreenLayout
                      label={language.verification_method_key}
                      rightRender={() => (
                        <>
                          <span>{domainVerification.verificationMethod}</span>
                        </>
                      )}
                    />
                  )}

                {(!values.ssoDomain ||
                  !values.ssoDomainVerified ||
                  values.ssoDomain !== domainVerification.ssoDomain) && (
                  <>
                    <FormFullScreenLayout
                      label={language.choose_verification_method_key}
                      isRequired={true}
                      rightRender={() => (
                        <>
                          <ItemSelector
                            key="selectedVerificationMethod"
                            name="selectedVerificationMethod"
                            placeholder={
                              language.choose_verification_method_key
                            }
                            multi={false}
                            options={selectableVerificationMethods}
                            disabled={!canEdit()}
                          />
                        </>
                      )}
                    />

                    <FormFullScreenLayout
                      rightRender={() => (
                        <SntInfoPanel className="mb-0">
                          <div>
                            {language.domain_verification_html_description_key}
                          </div>
                          <br />
                          <h4>{language.html_file_upload_key}</h4>
                          <div>{language.here_are_the_basic_steps_key}:</div>
                          <ol>
                            <li>
                              {language.download_the_key}{" "}
                              <a
                                onClick={(e) =>
                                  organizationClient.downloadVerificationFile(
                                    orgId
                                  )
                                }
                                title={language.html_verification_file_key}
                                href="##"
                              >
                                {language.html_verification_file_key}
                              </a>
                            </li>
                            <li>
                              {
                                language.upload_this_file_to_the_root_directory_of_key
                              }{" "}
                              <span>https://yourwebsite.com</span>
                            </li>
                            <li>
                              {
                                language.confirm_that_it_is_uploading_by_visiting_key
                              }{" "}
                              <span>
                                https://yourwebsite.com/html-verification-file-name.html
                              </span>
                            </li>
                            <li>{language.click_verify_key}</li>
                          </ol>
                        </SntInfoPanel>
                      )}
                    />
                  </>
                )}
              </SntPanel>
              <Col xs="12" className="mb-3">
                <SntInfoPanel className="mb-0">
                  {language.you_always_need_to_have_at_least_one_user_key}
                </SntInfoPanel>
              </Col>
              <Col xs="12" className="mb-3">
                {canEdit() && (
                  <Button
                    variant="sensolus"
                    disabled={
                      values.ssoDomain &&
                      values.ssoDomainVerified &&
                      values.ssoDomain === domainVerification.ssoDomain
                    }
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {language.verify_key}
                  </Button>
                )}
                <Button
                  variant="outline-sensolus"
                  className="ms-2"
                  onClick={() => history.push(`/trackers_admin?org=${orgId}`)}
                >
                  {language.cancel_key}
                </Button>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SSODomainVerification;
