import { useEffect } from "react";

// 3rd parties
import { useField } from "formik";

// owner
import SntCheckBox from "@/components/SntCheckBox/SntCheckBox";

const SntCheckboxField = ({ name, label, onChange, disabled }) => {
  // eslint-disable-next-line
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    onChange && onChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  const handleChange = (selectedValue) => {
    helper.setValue(selectedValue);
  };

  return (
    <SntCheckBox
      disabled={disabled}
      checked={field.value}
      onChange={(e) => {
        handleChange(e);
      }}
      label={label}
    />
  );
};

export default SntCheckboxField;
