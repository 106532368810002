import SntBatteryFullIcon from "@/components/Icons/SntBatteryFullIcon";
import moment from "../constants/SensolusMoment";
import convert from "convert-units";

const GlobalUtils = {
  getFile(entityType, id, resourceType, fileName, size) {
    if (
      fileName === undefined ||
      fileName === "" ||
      fileName?.includes("/") ||
      fileName === null
    )
      return fileName;
    let sizeQuery = size ? "?size=" + size : "";
    return (
      "/rest/file/" +
      entityType +
      "/" +
      id +
      "/" +
      resourceType +
      "/" +
      fileName +
      sizeQuery
    );
  },
  convertLongTimeToRelativePattern(elapsed, language) {
    let result = "0ms";
    let quotient = Math.floor(elapsed / 60);
    result = elapsed % 60 > 0 ? `${Math.floor(elapsed % 60)}s` : result;
    if (quotient >= 60) {
      result = quotient % 60 > 0 ? `${quotient % 60}m` : result;
      quotient = Math.floor(quotient / 60);
      if (quotient >= 24) {
        result =
          quotient % 24 > 0
            ? `${quotient % 24}${language.h_key} ${result}`
            : result;
        quotient = Math.floor(quotient / 24);
        result =
          quotient > 0 ? `${quotient + language.d_key} ${result}` : result;
      } else {
        result =
          quotient > 0 ? `${quotient + language.h_key} ${result}` : result;
      }
    } else {
      result = quotient > 0 ? `${quotient}m ${result}` : result;
    }
    return result;
  },

  getTotalFilterText(numberFilter) {
    numberFilter = numberFilter || 0;

    if (numberFilter > 10000) {
      numberFilter = Math.floor(numberFilter / 1000) + "K";
    }

    return numberFilter;
  },

  getKibanaLink(from, to, contextMap, contextValue, sort = "desc") {
    return `/kibana/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:${from},to:${to}))&_a=(columns:!(),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,key:ContextMap.${contextMap},negate:!f,params:(query:'${contextValue}'),type:phrase),query:(match_phrase:(ContextMap.${contextMap}:'${contextValue}')))),interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',${sort})))`;
  },
  getKibanaLinkByFilter(from, to, contextMap, arrayValues, sort = "desc") {
    let transform = arrayValues.map(
      (value) => `(match_phrase:(ContextMap.${contextMap}:'${value}'))`
    );
    let params = arrayValues.map((value) => `'${value}'`);
    let url =
      `/kibana/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:${from},to:${to}))&_a=(columns:!(),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,` +
      `key:ContextMap.${contextMap},negate:!f,params:!(${params.join(
        ","
      )}),type:phrases),query:(bool:(minimum_should_match:1,should:!(${transform.join(
        ","
      )}))))),interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',${sort})))`;
    return url;
  },
  getDurationStr(ms, language) {
    let negative = "";
    if (ms < 0) {
      ms = ms * -1;
      negative = "-";
    }
    let d = moment.duration(ms);
    let days = Math.floor(d.asDays());
    let daysPrefix = days !== 0 ? days + (language.d_key || "d") : "";
    daysPrefix && (daysPrefix += " ");
    let hours = Math.floor(d.asHours()) % 24;
    let hoursPrefix = hours !== 0 ? hours + (language.h_key || "h") : "";
    hoursPrefix && (hoursPrefix += " ");
    return (
      negative +
      daysPrefix +
      hoursPrefix +
      moment.utc(ms).format("m") +
      (language.asset_min_key || "min")
    );
  },

  getDistance(distance, fromUnit, unitDistance) {
    let toUnit = "km";
    if (unitDistance === "MILES") {
      toUnit = "mi";
    }

    let toBest = convert(convert(distance).from(fromUnit).to(toUnit))
      .from(toUnit)
      .toBest({ cutOffNumber: 1, exclude: ["cm", "mm"] });
    return Math.floor(toBest.val) + " " + toBest.unit;
  },

  renderBatteryImpact(option, batteryImpact) {
    const batteryImpacts = ["WORST", "WORSE", "BETTER", "BEST"];
    if (batteryImpact !== "NEUTRAL") {
      let color = "";
      if (batteryImpacts.indexOf(batteryImpact) < 1) {
        color = "#ef1f1b"; // red
      } else if (batteryImpacts.indexOf(batteryImpact) < 2) {
        color = "#ef8d1b"; // orange
      } else {
        color = "#4ac77c"; // orange
      }
      return batteryImpacts
        .slice(0, batteryImpacts.indexOf(batteryImpact) + 1)
        .map((item, index) => {
          return (
            <SntBatteryFullIcon
              key={index}
              size={24}
              color={color}
              className="me-2"
              style={{ marginTop: "-5px" }}
            />
          );
        });
    }
  },
};

export default GlobalUtils;
