const _deviceAdminRoute = {
  page: "/trackers_admin",
  updatePage: "/trackers_admin_update",
  createPage: "",
  getPage: (data) => {
    return `/trackers_admin?org=${data.orgId}`;
  },
  getUpdatePage: (data) => {
    data.org = data.org || data.orgId;

    const queryString = new URLSearchParams(data).toString();
    return `/trackers_admin_update?${queryString}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/trackers_admin_update?org=${data.orgId}`;
    return `/trackers_admin_update?`;
  },
};

export default _deviceAdminRoute;
