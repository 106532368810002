import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import organizationClient from "../../apis/organizationClient";
import SntPanel from "../../components/Panel/SntPanel";
import {
  FormFullScreenLayout,
  InputCheck,
} from "../../components/ReactBootstrap/FormValidation";
import InputText from "../../components/ReactBootstrap/FormValidation/InputText";
import ItemLanguageSelector from "../../components/ReactBootstrap/FormValidation/ItemLanguageSelector";
import ItemSelector from "../../components/ReactBootstrap/FormValidation/ItemSelector";
import ItemTimezoneSelector from "../../components/ReactBootstrap/FormValidation/ItemTimezoneSelector";
import LoaderSpinner from "../../components/ReactBootstrap/LoaderSpinner/LoaderSpinner";
import SntDialog from "../../components/SntDialog/SntDialog";
import SntInfoPanel from "../../components/SntInfo/SntInfoPanel";
import SntRoleSelector from "../../components/SntRoleSelector/SntRoleSelector";
import SntVisibilityFilterSelector from "../../components/SntVisibilitySelector/SntVisibilityFilterSelector";
import { useOrg } from "@/contexts/OrgContext.js";
import SntOrganisationVisibilitySelector from "@/components/SntOrganisationVisibilitySelector/SntOrganisationVisibilitySelector";
import SntColorPicker from "@/components/SntColorPicker/SntColorPicker.js";
import FileSelector from "../../components/ReactBootstrap/FormValidation/FileSelector.js";
import ClearImage from "@pages/CustomBranding/ClearImage.js";
import { LoginButtonPreview } from "@pages/SingleSignOn/LoginButtonPreview.js";

const FirstSingleSignOn = ({ reload }) => {
  const history = useHistory();
  const { orgId } = useOrg();
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap, features } = loginInfo;
  const language = useSelector((state) => state.language);
  const [loading, setLoading] = useState(true);
  const authenticationTypes = [
    { label: language.use_sensolus_as_identity_provider_key, value: "local" },
    {
      label: language.use_openid_connect_identity_provider_key,
      value: "openid_connect",
    },
    { label: language.use_saml_identity_provider, value: "saml2" },
  ];

  const validationSchema = Yup.object().shape({
    authenticationType: Yup.object().shape({
      value: Yup.string().required(language.valid_required_key),
    }),
    ssoName: Yup.string().when("authenticationType.value", {
      is: (authenticationType) =>
        authenticationType === "openid_connect" ||
        authenticationType === "saml2",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    clientId: Yup.string().when("authenticationType.value", {
      is: "openid_connect",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    clientSecret: Yup.string().when("authenticationType.value", {
      is: "openid_connect",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    authUrl: Yup.string().when("authenticationType.value", {
      is: "openid_connect",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    tokenUrl: Yup.string().when("authenticationType.value", {
      is: "openid_connect",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    apiUrl: Yup.string().when("authenticationType.value", {
      is: "openid_connect",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),

    idpCertificate: Yup.string().when("authenticationType.value", {
      is: "saml2",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    idpEntityId: Yup.string().when("authenticationType.value", {
      is: "saml2",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    idpSsoUrl: Yup.string().when("authenticationType.value", {
      is: "saml2",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    spEntityId: Yup.string().when("authenticationType.value", {
      is: "saml2",
      then: Yup.string().required(language.valid_required_key),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    newUserRole: Yup.object().shape({
      value: Yup.string().when(["authenticationType.value", "allowNewUser"], {
        is: (authenticationType, allowNewUser) =>
          (authenticationType === "openid_connect" ||
            authenticationType === "saml2") &&
          allowNewUser,
        then: Yup.string().required(language.valid_required_key),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
    newUserLanguage: Yup.object().shape({
      value: Yup.string().when(["authenticationType.value", "allowNewUser"], {
        is: (authenticationType, allowNewUser) =>
          (authenticationType === "openid_connect" ||
            authenticationType === "saml2") &&
          allowNewUser,
        then: Yup.string().required(language.valid_required_key),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
    newUserTimezone: Yup.object().shape({
      value: Yup.string().when(["authenticationType.value", "allowNewUser"], {
        is: (authenticationType, allowNewUser) =>
          (authenticationType === "openid_connect" ||
            authenticationType === "saml2") &&
          allowNewUser,
        then: Yup.string().required(language.valid_required_key),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
  });

  const [isShowSuccessResponse, setShowSuccessResponse] = useState(false);
  const [isShowErrorResponse, setShowErrorResponse] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [ssoSetting, setSSOSetting] = useState({
    organisationType: "NORMAL",
    authenticationType: { value: "" },
    allowNewUser: false,
    clientId: "",
    clientSecret: "",
    authUrl: "",
    tokenUrl: "",
    apiUrl: "",
    idpCertificate: "",
    idpEntityId: "",
    idpSsoUrl: "",
    spEntityId: "",
    ssoName: "",
    logoutUrl: "",
    newUserRole: { value: null },
    newUserVisibilityFilters: [],
    newOrganisationFilters: [],
    newUserLanguage: { value: null },
    newUserTimezone: {
      value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    loginButtonBackgroundColor: "",
    loginButtonBorderColor: "",
    loginButtonTextColor: "",
    loginButtonText: "",
    buttonImage: null,
  });

  useEffect(() => {
    organizationClient.findOne({ id: orgId }).then(({ data }) => {
      updateFormData(data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, orgId]);

  const updateSSOSetting = (id, values) => {
    organizationClient
      .updateSSOSetting1(id, values)
      .then(({ data }) => {
        setShowSuccessResponse(true);
        setDialogMessage(language.update_sso_setting_successfully_key);
        updateFormData(data);
      })
      .catch((error) => {
        setShowErrorResponse(true);
        setDialogMessage(error.response.data.message);
      });
  };

  function updateFormData(data) {
    let existingSSOSetting = { ...ssoSetting };
    existingSSOSetting.organisationType = data.organisationType;
    let ssoSetting1 = data.ssoSetting1;
    if (ssoSetting1) {
      existingSSOSetting.authenticationType = {
        value: ssoSetting1.authenticationType,
      };
      if (
        ssoSetting1.authenticationType === "openid_connect" ||
        ssoSetting1.authenticationType === "saml2"
      ) {
        if (ssoSetting1.authenticationType === "openid_connect") {
          existingSSOSetting.clientId = ssoSetting1.clientId;
          existingSSOSetting.clientSecret = ssoSetting1.clientSecret;
          existingSSOSetting.authUrl = ssoSetting1.authUrl;
          existingSSOSetting.tokenUrl = ssoSetting1.tokenUrl;
          existingSSOSetting.apiUrl = ssoSetting1.apiUrl;
        } else if (ssoSetting1.authenticationType === "saml2") {
          existingSSOSetting.idpCertificate = ssoSetting1.idpCertificate;
          existingSSOSetting.idpEntityId = ssoSetting1.idpEntityId;
          existingSSOSetting.idpSsoUrl = ssoSetting1.idpSsoUrl;
          existingSSOSetting.spEntityId = ssoSetting1.spEntityId;
        }

        existingSSOSetting.ssoName = ssoSetting1.ssoName;
        existingSSOSetting.logoutUrl = ssoSetting1.logoutUrl;
        existingSSOSetting.allowNewUser = ssoSetting1.allowNewUser;
        if (existingSSOSetting.allowNewUser) {
          existingSSOSetting.newUserRole.value = ssoSetting1.newUserRole.id;
          existingSSOSetting.newUserVisibilityFilters = ssoSetting1.newVisibilityFilters.map(
            (visibilityFilter) => ({
              value: visibilityFilter.id,
            })
          );
          existingSSOSetting.newOrganisationFilters = ssoSetting1.newOrganisationFilters.map(
            (orgFilter) => ({
              value: orgFilter.id,
            })
          );

          existingSSOSetting.newUserLanguage.value =
            ssoSetting1.newUserLanguage;
          existingSSOSetting.newUserTimezone.value =
            ssoSetting1.newUserTimezone;
        }

        existingSSOSetting.loginButtonBackgroundColor =
          ssoSetting1.loginButtonBackgroundColor;
        existingSSOSetting.loginButtonBorderColor =
          ssoSetting1.loginButtonBorderColor;
        existingSSOSetting.loginButtonTextColor =
          ssoSetting1.loginButtonTextColor;
        existingSSOSetting.loginButtonText = ssoSetting1.loginButtonText;
        existingSSOSetting.buttonImage = ssoSetting1.buttonImage;
      }
    }
    setSSOSetting(existingSSOSetting);
  }

  function data2Server(data) {
    let datToSubmit = {};
    datToSubmit.authenticationType = data.authenticationType.value;
    datToSubmit.ssoDomainVerified = data.ssoDomainVerified;
    datToSubmit.verificationMethod = data.verificationMethod;
    if (
      datToSubmit.authenticationType === "openid_connect" ||
      datToSubmit.authenticationType === "saml2"
    ) {
      if (datToSubmit.authenticationType === "openid_connect") {
        datToSubmit.clientId = data.clientId;
        datToSubmit.clientSecret = data.clientSecret;
        datToSubmit.authUrl = data.authUrl;
        datToSubmit.tokenUrl = data.tokenUrl;
        datToSubmit.apiUrl = data.apiUrl;
      } else if (datToSubmit.authenticationType === "saml2") {
        datToSubmit.idpCertificate = data.idpCertificate;
        datToSubmit.idpEntityId = data.idpEntityId;
        datToSubmit.idpSsoUrl = data.idpSsoUrl;
        datToSubmit.spEntityId = data.spEntityId;
      }

      datToSubmit.ssoName = data.ssoName;
      datToSubmit.logoutUrl = data.logoutUrl;
      datToSubmit.allowNewUser = data.allowNewUser;
      if (datToSubmit.allowNewUser) {
        datToSubmit.accessRight = data.newUserRole.value;
        datToSubmit.newUserVisibilityFilters = data.newUserVisibilityFilters.map(
          (visibilityFilter) => {
            return visibilityFilter.value;
          }
        );
        datToSubmit.newOrganisationFilters = data.newOrganisationFilters.map(
          (orgFilter) => {
            return orgFilter.value;
          }
        );
        datToSubmit.newUserLanguage = data.newUserLanguage.value;
        datToSubmit.newUserTimezone = data.newUserTimezone.value;
      }

      datToSubmit.loginButtonBackgroundColor = data.loginButtonBackgroundColor;
      datToSubmit.loginButtonBorderColor = data.loginButtonBorderColor;
      datToSubmit.loginButtonTextColor = data.loginButtonTextColor;
      datToSubmit.loginButtonText = data.loginButtonText;
      datToSubmit.buttonImage = data.buttonImage;
    }

    return datToSubmit;
  }

  function onSubmit(values) {
    let datToSubmit = data2Server(values);
    updateSSOSetting(orgId, datToSubmit);
  }

  const canEdit = () => {
    return permissionsMap["ORGANIZATION_MANAGE"];
  };

  const onChangeImg = (image, setSrc) => {
    // Assuming only image
    let file = image.file;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setSrc(reader.result);
    };
  };

  const getEmptyButtonLogo = () => {
    return {
      file: null,
      buttonImage: "",
    };
  };

  const renderImg = (src, heightInPx, marginTopPx) => {
    return (
      <Col xs={4} xl={6} className="px-0 mb-3">
        <div style={{ marginTop: `${marginTopPx || 0}px` }}>
          <img style={{ height: "16px", width: "16px" }} src={src} alt=""></img>
        </div>
      </Col>
    );
  };

  const ChooseImageLayout = ({ image, chooseImgBtn, clearImg }) => {
    return (
      <Row>
        {image && (
          <Col xl={1}>
            <>{image}</>
          </Col>
        )}
        <Col xl={1} lg={1}>
          {chooseImgBtn && chooseImgBtn()}
        </Col>
        <Col xl={1} lg={1}>
          {clearImg && clearImg()}
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <LoaderSpinner loading={loading} />
      <SntDialog
        isShow={isShowSuccessResponse}
        onSave={() => {
          setShowSuccessResponse(false);
          setShowErrorResponse(false);
          setDialogMessage(null);
        }}
        title={language.notifications_key}
        isShowCloseButton={false}
        isShowSaveButton={true}
        saveTxt={language.ok_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>
      <SntDialog
        isShow={isShowErrorResponse}
        onSave={() => {
          setShowErrorResponse(false);
          setShowSuccessResponse(false);
          setDialogMessage(null);
        }}
        title={language.error_key}
        isShowSaveButton={true}
        isShowCloseButton={false}
        btnSaveClassName="danger"
        saveTxt={language.ok_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>

      <Formik
        initialValues={ssoSetting}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          let authenticationType = values.authenticationType.value;
          return (
            <Form className="form-horizontal">
              <SntPanel title={language.identity_provider_key}>
                <FormFullScreenLayout
                  rightRender={() => (
                    <SntInfoPanel className="mb-0">
                      {language.you_always_need_to_have_at_least_one_user_key}
                    </SntInfoPanel>
                  )}
                />
                <FormFullScreenLayout
                  label={`${language.identity_provider_key}*`}
                  rightRender={() => (
                    <ItemSelector
                      name="authenticationType"
                      placeholder={language.identity_provider_key}
                      multi={false}
                      options={authenticationTypes}
                      disabled={!canEdit()}
                    />
                  )}
                />
              </SntPanel>

              {["openid_connect", "saml2"].includes(authenticationType) && (
                <SntPanel
                  title={
                    language.openid_connect_identity_provider_configuration
                  }
                >
                  <>
                    <FormFullScreenLayout
                      label={language.name_key}
                      isRequired={true}
                      rightRender={() => (
                        <InputText
                          name="ssoName"
                          placeholder={language.name_key}
                          disabled={!canEdit()}
                        />
                      )}
                    />

                    {authenticationType === "openid_connect" ? (
                      <>
                        <FormFullScreenLayout
                          label={language.client_id_key}
                          isRequired={true}
                          rightRender={() => (
                            <InputText
                              key="clientId"
                              name="clientId"
                              placeholder={language.client_id_key}
                              disabled={!canEdit()}
                            />
                          )}
                        />

                        <FormFullScreenLayout
                          label={language.client_secret_key}
                          isRequired={true}
                          rightRender={() => (
                            <InputText
                              key="clientSecret"
                              name="clientSecret"
                              placeholder={language.client_secret_key}
                              disabled={!canEdit()}
                            />
                          )}
                        />

                        <FormFullScreenLayout
                          label={language.auth_url_key}
                          isRequired={true}
                          rightRender={() => (
                            <InputText
                              key="authUrl"
                              name="authUrl"
                              placeholder={language.auth_url_key}
                              disabled={!canEdit()}
                            />
                          )}
                        />

                        <FormFullScreenLayout
                          label={language.token_url_key}
                          isRequired={true}
                          rightRender={() => (
                            <InputText
                              key="tokenUrl"
                              name="tokenUrl"
                              placeholder={language.token_url_key}
                              disabled={!canEdit()}
                            />
                          )}
                        />

                        <FormFullScreenLayout
                          label={language.api_url_key}
                          isRequired={true}
                          rightRender={() => (
                            <InputText
                              key="apiUrl"
                              name="apiUrl"
                              placeholder={language.api_url_key}
                              disabled={!canEdit()}
                            />
                          )}
                        />
                      </>
                    ) : (
                      authenticationType === "saml2" && (
                        <>
                          <FormFullScreenLayout
                            label={language.idp_entity_id_url_key}
                            isRequired={true}
                            rightRender={() => (
                              <InputText
                                key="idpEntityId"
                                name="idpEntityId"
                                placeholder={language.idp_entity_id_url_key}
                                disabled={!canEdit()}
                              />
                            )}
                          />

                          <FormFullScreenLayout
                            label={language.idp_cert_base64_key}
                            isRequired={true}
                            rightRender={() => (
                              <InputText
                                key="idpCertificate"
                                name="idpCertificate"
                                placeholder={language.idp_cert_base64_key}
                                disabled={!canEdit()}
                              />
                            )}
                          />

                          <FormFullScreenLayout
                            label={language.idp_sso_url_key}
                            isRequired={true}
                            rightRender={() => (
                              <InputText
                                key="idpSsoUrl"
                                name="idpSsoUrl"
                                placeholder={language.idp_sso_url_key}
                                disabled={!canEdit()}
                              />
                            )}
                          />

                          <FormFullScreenLayout
                            label={language.sp_entity_id_url_key}
                            isRequired={true}
                            rightRender={() => (
                              <InputText
                                key="spEntityId"
                                name="spEntityId"
                                placeholder={language.sp_entity_id_url_key}
                                disabled={!canEdit()}
                              />
                            )}
                          />
                        </>
                      )
                    )}

                    <FormFullScreenLayout
                      label={language.logout_url_key}
                      isRequired={true}
                      rightRender={() => (
                        <InputText
                          key="logoutUrl"
                          name="logoutUrl"
                          placeholder={language.logout_url_key}
                          disabled={!canEdit()}
                        />
                      )}
                    />

                    <FormFullScreenLayout
                      label={language.login_button_background_color_key}
                      isRequired={false}
                      rightRender={() => (
                        <SntColorPicker
                          name="loginButtonBackgroundColor"
                          hexColor={values.loginButtonBackgroundColor}
                          disabled={!canEdit()}
                        />
                      )}
                    />

                    <FormFullScreenLayout
                      label={language.login_button_border_color_key}
                      isRequired={false}
                      rightRender={() => (
                        <SntColorPicker
                          name="loginButtonBorderColor"
                          hexColor={values.loginButtonBorderColor}
                          disabled={!canEdit()}
                        />
                      )}
                    />

                    <FormFullScreenLayout
                      label={language.login_button_text_color_key}
                      isRequired={false}
                      rightRender={() => (
                        <SntColorPicker
                          name="loginButtonTextColor"
                          hexColor={values.loginButtonTextColor}
                          disabled={!canEdit()}
                        />
                      )}
                    />

                    <FormFullScreenLayout
                      label={language.login_button_text_key}
                      isRequired={false}
                      rightRender={() => (
                        <InputText
                          key="loginButtonText"
                          name="loginButtonText"
                          placeholder={language.login_button_text_key}
                          disabled={!canEdit()}
                        />
                      )}
                    />

                    <FormFullScreenLayout
                      label={language.login_button_image_key}
                      rightRender={() => (
                        <>
                          <ChooseImageLayout
                            image={
                              values.buttonImage &&
                              renderImg(values.buttonImage, 24, 4)
                            }
                            chooseImgBtn={() => (
                              <FileSelector
                                name="file"
                                chooseLabel={language.change_key}
                                accept={
                                  "image/x-png,image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png"
                                }
                                disabled={!canEdit()}
                                onChange={(file) => {
                                  onChangeImg(file, (src) => {
                                    setFieldValue("buttonImage", src);
                                  });
                                }}
                              />
                            )}
                            clearImg={() => (
                              <ClearImage
                                onClearClick={() => {
                                  setFieldValue("buttonImage", null);
                                }}
                                defaultMapValue={getEmptyButtonLogo()}
                                disabled={values.buttonImage === null}
                              />
                            )}
                          />
                        </>
                      )}
                    />

                    <FormFullScreenLayout
                      label={language.preview}
                      isRequired={false}
                      rightRender={() => (
                        <LoginButtonPreview
                          buttonImageSrc={values.buttonImage}
                          loginButtonBackgroundColor={
                            values.loginButtonBackgroundColor
                          }
                          loginButtonBorderColor={values.loginButtonBorderColor}
                          loginButtonTextColor={values.loginButtonTextColor}
                          loginButtonText={values.loginButtonText}
                        />
                      )}
                    />

                    {["NORMAL", "PARTNER"].includes(
                      values.organisationType
                    ) && (
                      <>
                        <FormFullScreenLayout
                          rightRender={() => (
                            <h5 className="fw-bold text-start">
                              {language.auto_registration_of_new_user}
                            </h5>
                          )}
                        />
                        <FormFullScreenLayout
                          label={language.new_user_key}
                          rightRender={() => (
                            <InputCheck
                              name="allowNewUser"
                              label={language.do_allow_to_add_users_key + ":"}
                              disabled={!canEdit()}
                            />
                          )}
                        />

                        {values.allowNewUser && (
                          <>
                            <FormFullScreenLayout
                              label={language.select_language}
                              isRequired={true}
                              rightRender={() => (
                                <ItemLanguageSelector name="newUserLanguage" />
                              )}
                            />

                            <FormFullScreenLayout
                              label={language.select_timezone_key}
                              isRequired={true}
                              rightRender={() => (
                                <ItemTimezoneSelector name="newUserTimezone" />
                              )}
                            />

                            <FormFullScreenLayout
                              label={language.select_role_key}
                              isRequired={true}
                              rightRender={() => (
                                <SntRoleSelector
                                  name="newUserRole"
                                  orgId={orgId}
                                />
                              )}
                            />

                            <FormFullScreenLayout
                              label={language.visibility_filters_key}
                              rightRender={() => (
                                <>
                                  <SntVisibilityFilterSelector
                                    name="newUserVisibilityFilters"
                                    orgId={orgId}
                                  />
                                </>
                              )}
                            />

                            {features.organisation_visibility_filters &&
                              permissionsMap[
                                "ORGANISATION_VISIBILITY_FILTER_VIEW"
                              ] &&
                              (ssoSetting?.organisationType === "PARTNER" ||
                                ssoSetting?.organisationType === "SYSTEM") && (
                                <FormFullScreenLayout
                                  label={
                                    language.organisation_visibility_filters_key
                                  }
                                  rightRender={() => (
                                    <>
                                      <SntOrganisationVisibilitySelector
                                        name="newOrganisationFilters"
                                        orgId={orgId}
                                      />
                                    </>
                                  )}
                                />
                              )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </SntPanel>
              )}

              <Col xs="12" className="mb-3">
                {canEdit() && (
                  <Button
                    variant="sensolus"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {language.save_key}
                  </Button>
                )}
                <Button
                  variant="outline-sensolus"
                  className="ms-2"
                  onClick={() => history.push(`/trackers_admin?org=${orgId}`)}
                >
                  {language.cancel_key}
                </Button>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FirstSingleSignOn;
