import { useFormikContext } from "formik";

import { useSelector } from "react-redux";
import styled from "styled-components";
import SntLink from "../../components/ReactBootstrap/SntLink";

const Styles = styled.div`
  .disabled-link {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
}
`;

const ClearImage = ({
  defaultMapValue = {},
  onClearClick,
  disabled = false,
}) => {
  const language = useSelector((state) => state.language);
  const { setFieldValue } = useFormikContext();

  const onClick = () => {
    if (!disabled) {
      onClearClick && onClearClick();
      if (defaultMapValue) {
        for (var key in defaultMapValue) {
          let value = defaultMapValue[key];
          setFieldValue(key + "", value);
        }
      }
    }
  };

  return (
    <Styles>
      <div className="pt-2">
        <SntLink
          className={`${disabled ? "disabled-link" : ""}`}
          onClick={onClick}
        >
          {language.clear_image_key}
        </SntLink>
      </div>
    </Styles>
  );
};

export default ClearImage;
