import SntIcon from "./SntIcon";

const SntBulkIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M15.8538 1.31607C16.0487 1.50104 16.0487 1.826 15.8538 2.02097L14.9339 2.93584L13.0592 1.0611L13.979 0.14623C14.174 -0.0487432 14.499 -0.0487432 14.6839 0.14623L15.8538 1.31607Z" />
      <path d="M8.87474 9L14.404 3.46577L12.5292 1.59103L7 7.12527V9H8.87474Z" />
      <path d="M6 6.71131L8.47272 4.23636L8 4L0 8L8 12L16 8L12.8573 6.42867L9.28922 10H6V6.71131Z" />
      <path d="M1 9.5L8 13L15 9.5L16 10L8 14L0 10L1 9.5Z" />
      <path d="M1 11.5L8 15L15 11.5L16 12L8 16L0 12L1 11.5Z" />
    </SntIcon>
  );
};

export default SntBulkIcon;
