import { useField, useFormikContext } from "formik";

import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import InputText from "./InputText";

const FileSelector = ({
  name,
  filePathName,
  className,
  accept = "",
  chooseLabel = "",
  disabled = false,
  onChange,
}) => {
  const language = useSelector((state) => state.language);

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helper] = useField(name || "file");

  const { setFieldValue } = useFormikContext();

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const onChangeFile = (event) => {
    helper.setValue(event.currentTarget.files[0]);
    if (filePathName) {
      setFieldValue(filePathName, event.currentTarget.files[0].name || "");
    }

    onChange &&
      onChange({
        file: event.currentTarget.files[0],
        src: event.currentTarget.files[0].name,
      });
    event.currentTarget.value = "";
  };

  return (
    <Row>
      {!disabled && (
        <Col className={filePathName ? "col-lg-4 col-12" : "col-12"}>
          <Form.Label
            className={`${
              className ? className : ""
            } btn btn-sensolus-greylight`}
          >
            <Form.Control
              type="file"
              accept={accept}
              disabled={disabled}
              style={{ display: "none" }}
              onChange={onChangeFile}
            />
            {chooseLabel || language.choose_file_key}
          </Form.Label>
        </Col>
      )}

      {filePathName && (
        <Col xs="12" lg="8">
          <InputText name={filePathName} disabled={true} />
        </Col>
      )}

      <Col xs="12">
        <Form.Control.Feedback
          type="invalid"
          className={`${formGroupClass} ${
            formGroupClass === "is-invalid" ? "d-block" : "d-none"
          }`}
        >
          {meta.error}
        </Form.Control.Feedback>
      </Col>
    </Row>
  );
};

export default FileSelector;
