import { useState } from "react";

import { createFilter } from "react-select";
import moment from "../../../constants/SensolusMoment";
import ItemSelector from "./ItemSelector";

const ItemTimezoneSelector = (props) => {
  const [timezones] = useState(() => {
    let _citiesName = moment.tz.names();

    let cities = [];
    for (let i = 0; i < _citiesName.length; i++) {
      const key = _citiesName[i];

      let deprecatedList = ["EST", "MST", "HST"]; // @see TimeZone#toZoneId() in Java to compare
      if (deprecatedList.includes(key)) {
        continue;
      }

      cities.push({
        label: `(GMT ${moment.tz(key).format("Z")}) ${key}`,
        value: key,
        offset: moment.tz(key).format("Z"),
      });
    }

    cities.sort((a, b) => {
      return (
        parseInt(a.offset.replace(":", ""), 10) -
        parseInt(b.offset.replace(":", ""), 10)
      );
    });

    return cities;
  });

  return (
    <ItemSelector
      {...props}
      options={timezones}
      filterOption={createFilter({ ignoreAccents: false })}
    ></ItemSelector>
  );
};

export default ItemTimezoneSelector;
