import { useEffect, useMemo, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import PagePermissionWarning from "../../containers/AdminLayout/PagePermissionWarning/PagePermissionWarning";
import { useOrg } from "../../contexts/OrgContext";

import privateCellularClient from "@/apis/privateCellularClient";
import $ from "jquery";
import { Button } from "react-bootstrap";
import IcomoonIcon from "../../components/Icons/IcomoonIcon";
import SntPanel from "../../components/Panel/SntPanel";
import SntLink from "../../components/ReactBootstrap/SntLink";
import SntDialog from "../../components/SntDialog/SntDialog";
import SntInfoPanel from "../../components/SntInfo/SntInfoPanel";
import SntWarning from "../../components/SntInfo/SntWarning";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import StringUtils from "../../utils/StringUtils";
import PageHeader from "@/containers/PageHeader/PageHeader";
import { SntAdvancedSearchTableViewCard } from "@/components/SntTableViewCard";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import ActionToolEnum from "../../containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "../../containers/AdminLayout/Toolbar/ActionToolType";
import SntText from "@/components/ReactBootstrap/SntText";
import searchUIClient from "@/apis/searchUIClient";

const PAGE_NAME = "PRIVATE_CELLLAR";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "PRIVATE_CELLULAR_TOWER",
  filtersVisibleAtInit: ["cellId"],
  optionsSettings: {},
};

const PrivateCellular = () => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap, features, organisation } = loginInfo;

  const fileRef = useRef();
  const [uploadError, setUploadError] = useState("");

  const [dialogMessage, setDialogMessage] = useState(language.error_key);
  const [isShowDialogMessage, setShowDialogMessage] = useState(false);
  const [reload, setReload] = useState(false);

  const onUploadClicked = () => {
    $(fileRef.current).click();
  };

  useEffect(() => {
    $(fileRef.current).change(function () {
      let render = new FileReader(),
        input = this;

      render.onload = function (e) {
        let form = $(fileRef.current).closest("form");
        let dataForm = new FormData(form[0]);

        privateCellularClient
          .uploadPrivateCellularFile(
            orgId || loginInfo.organisation.id,
            dataForm
          )
          .then(({ data }) => {
            setUploadError(null);
            setDialogMessage(
              StringUtils.replaceStr(language.v43_successful_key, data)
            );
            setShowDialogMessage(true);
            setReload((pre) => !pre);
          })
          .catch(({ response }) => {
            setDialogMessage(
              response?.data?.message || language.update_fail_keyupdate_fail_key
            );
            setShowDialogMessage(true);
            setUploadError(response.data.message);
          });
      };
      render.readAsDataURL(input.files[0]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(
    () => [
      {
        key: "cellId",
        title: language.cell_id,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "cellularInfo",
        title: language.cellular_tower_info,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          var info =
            "mcc: " +
            (full.mcc || language.unknown_key) +
            ", mnc: " +
            (full.net || language.unknown_key) +
            ", cell: " +
            (full.cellId || language.unknown_key);
          return <div>{info}</div>;
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.mcc) {
            text += " - " + data.mcc;
          }
          if (data.net) {
            text += " - " + data.net;
          }
          if (data.cellId) {
            text += " - " + data.cellId;
          }
          return [text];
        },
      },
      {
        key: "actualLocation",
        title: language.v41_locations_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.actualLat && full.actualLng ? (
            <>
              <div className="d-flex">
                latitude: {full.actualLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.actualLat +
                    "," +
                    full.actualLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.actualLng}</div>
            </>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.actualLat != undefined && data.actualLat != null) {
            text += data.actualLat + " - ";
          }
          if (data.actualLng != undefined && data.actualLng != null) {
            text += data.actualLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.actualLat != undefined && data.actualLat != null) {
            text += data.actualLat + " - ";
          }
          if (data.actualLng != undefined && data.actualLng != null) {
            text += data.actualLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const defaultColumnInit = useMemo(
    () => ["cellId", "cellularInfo", "actualLocation", "createdAt"],
    []
  );

  const table = useRef();
  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  useEffect(() => {
    // reload button
    table.current && table.current.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      params.selectedOrgId = orgId || loginInfo.organisation.id;

      table.current.setLoading(true);
      searchUIClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const canEdit = () => {
    return (
      features["only_private_cellular_resolution"] &&
      permissionsMap["PRIVATE_CELLULAR_MANAGE"] &&
      (organisation.organisationType !== "PARTNER" ||
        features["partner_org_manage"])
    );
  };

  return (
    <>
      <SntDialog
        isShow={isShowDialogMessage}
        onSave={() => {
          setShowDialogMessage(false);
        }}
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.notifications_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>

      <PagePermissionWarning pageName={PAGE_NAME} />

      <PageHeader
        title={language.UserPermissionCategory_PRIVATE_CELLULAR_title_key}
        tabTitle={language.UserPermissionCategory_PRIVATE_CELLULAR_title_key}
      />

      <SntPanel title={language.configure_private_cellular}>
        <SntInfoPanel>
          {ReactHtmlParser(language.private_cellular_header_key)}
          <ul>
            <li>{language.mac_address_step_1_key}</li>
            <li>{language.private_cellular_step_2_key}</li>
            <li>{language.mac_address_step_3_key}</li>
          </ul>
        </SntInfoPanel>

        {uploadError && (
          <SntWarning>
            <IcomoonIcon
              icon="warning"
              size="18"
              className="me-2"
            ></IcomoonIcon>
            {uploadError}
          </SntWarning>
        )}

        <form>
          {features["only_private_cellular_resolution"] &&
            permissionsMap["PRIVATE_CELLULAR_MANAGE"] &&
            (organisation.organisationType !== "PARTNER" ||
              features["partner_org_manage"]) && (
              <div className="d-flex">
                <div className="align-self-center">
                  <Button
                    variant="sensolus-greylight"
                    type="button"
                    className="me-2"
                    disabled={!canEdit()}
                    onClick={onUploadClicked}
                  >
                    <span className="fw-bold">{language.upload_excel_key}</span>
                  </Button>
                  <input
                    name="file"
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    disabled={!canEdit()}
                    ref={fileRef}
                  />
                </div>
                <div className="align-self-center me-1">
                  <SntLink
                    onClick={() => privateCellularClient.downloadTemplateFile()}
                  >
                    {language.download_example_excel_key}
                  </SntLink>
                </div>
                <span className="align-self-center"> | </span>
                <div className="align-self-center ms-1">
                  <SntLink
                    onClick={() => privateCellularClient.exportAsExcel(orgId)}
                  >
                    {language.export_as_xls_key}
                  </SntLink>
                </div>
              </div>
            )}
        </form>
      </SntPanel>

      <AdminLayout
        isShowHeader={false}
        browserTabTitle={
          language.UserPermissionCategory_PRIVATE_CELLULAR_title_key
        }
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        totalLabel={language.items_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={[]}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </>
  );
};

export default PrivateCellular;
