import { ItemSelector } from "../ReactBootstrap/FormValidation";
import { useGetOrgVisibilityFilter } from "@/apis/organisationFilterClient";

const SntOrganisationVisibilitySelector = (props) => {
  const { orgId } = { ...props };

  const orgVisibilityFilters = useGetOrgVisibilityFilter(orgId);

  return (
    <ItemSelector {...props} multi={true} options={orgVisibilityFilters} />
  );
};

export default SntOrganisationVisibilitySelector;
