import { apiClient } from "./apiClient";

export default {
  getById(id) {
    return apiClient.get("/rest/device_usage_profile/" + id);
  },

  createUsageProfile(usageProfile) {
    return apiClient.post("/rest/device_usage_profile", usageProfile);
  },

  updateUsageProfile(id, usageProfile) {
    return apiClient.put(`/rest/device_usage_profile/${id}`, usageProfile);
  },

  clone(id, params) {
    return apiClient.post(
      `/rest/device_usage_profile/clone/${id}?name=${params.name}&referenceProduct=${params.referenceProduct}`,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
  },

  delete(id) {
    return apiClient.delete(`/rest/device_usage_profile/${id}`);
  },

  getFwParamByProductKeys(productKeys) {
    return apiClient.post(
      "/rest/device_usage_profile/getFwParamByProductKeys",
      productKeys,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
  },

  getByDevice(deviceId) {
    return apiClient.get(
      "/rest/device_usage_profile/" + deviceId + "/getByDevice"
    );
  },

  getByOrg(orgId, techKeys) {
    return apiClient.post(
      "/rest/device_usage_profile/getByOrg/" + orgId,
      techKeys,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
  },

  getAllRelativeProductsByReference(referenceProductKey) {
    return apiClient.get(
      `/rest/device_usage_profile/getAllRelativeProductsByReference/${referenceProductKey}`
    );
  },

  exportAsJSON(id) {
    return apiClient.get("/rest/device_usage_profile/exportAsJSON/" + id);
  },

  import(jsonProfile) {
    return apiClient.post("/rest/device_usage_profile/import", jsonProfile, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },
};
