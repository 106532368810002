const DeviceSettingTabs = {
  asset_info_tab: "asset_info_tab",
  asset_services_tab: "asset_services_tab",
  profile_info_tab: "profile_info_tab",
  sigfox_settings_tab: "sigfox_settings_tab",
  invoicing_tab: "invoicing_tab",
  tracker_configuration_tab: "tracker_configuration_tab",
  manufacturing_info: "manufacturing_info",
  battery_tab: "battery_tab",
  diagnostic_tab: "diagnostic_tab",
  firmware: "firmware_tab",
};

const DeviceViewTabs = {
  asset_details_tab: "asset_details_tab",
  asset_journey_tab: "asset_journey_tab",
  asset_location_tab: "asset_location_tab",
  motion_tab: "motion_tab",
  flow_tab: "flow_tab",
  keyhanger_beacon_scanned_tab: "keyhanger_beacon_scanned_tab",
  geozone_visit_tab: "geozone_visit_tab",
  process_tab: "process_tab",
  orientation_state_tab: "orientation_tab",
  orientation_event_tab: "orientation_event_tab",
  shock_event_tab: "shock_event_tab",
  external_sensor_tab: "external_sensor_tab",
  assets_sensors_tab: "assets_sensors_tab",
  scanned_assets: "scanned_assets",
  alerts_tab: "alerts_tab",
  ble_tab: "ble_tab",
  sigfox_message_tab: "sigfox_message_tab",
  debug_tab: "debug_tab",
  timeseries_tab: "timeseries_tab",
  ble_signal: "ble_signal",
  journey_timeline_tab: "journey_timeline_tab",
  tracker_simulator_tab: "tracker_simulator_tab",
  external_data_tab: "external_data_tab",
  utilization_tab: "utilization_tab",
  maintenance_tab: "maintenance_tab",
  location_history_tab: "location_history_tab",
  scanned_anchors: "scanned_anchors",
};

export { DeviceSettingTabs, DeviceViewTabs };
