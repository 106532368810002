import { useEffect, useState } from "react";
import { apiClient } from "./apiClient";

export default {
  findAll(params) {
    return apiClient.get(
      `/rest/visibilityFilter${
        params && params.orgId ? "?orgId=" + params.orgId : ""
      }`
    );
  },

  getById(id) {
    return apiClient.get(`/rest/visibilityFilter/${id}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/visibilityFilter/${id}`);
  },

  update(id, body) {
    return apiClient.put(`/rest/visibilityFilter/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  create(body) {
    return apiClient.post(`/rest/visibilityFilter`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  check(id) {
    return apiClient.get(`/rest/visibilityFilter/checkVisibility/${id}`);
  },
};
export const getVisibilityFilter = {
  getAll(orgId) {
    return apiClient.get(
      `/rest/visibilityFilter${orgId ? "?orgId=" + orgId : ""}`
    );
  },
};
export const useGetVisibilityFilter = (orgId, permissionsMap, features) => {
  const [visibilityFilters, setVisibilityFilters] = useState([]);
  useEffect(() => {
    if (
      features["visibility_filters"] &&
      permissionsMap["VISIBILITY_FILTER_VIEW"]
    ) {
      getVisibilityFilter.getAll(orgId).then(({ data }) => {
        let filterList = [];
        for (let i = 0; i < data.length; i++) {
          filterList.push({
            label: data[i].name,
            value: data[i].id,
          });
        }
        setVisibilityFilters(filterList);
      });
    }
  }, [orgId, permissionsMap, features]);
  return visibilityFilters;
};
