import IcomoonIcon from "../Icons/IcomoonIcon";
import { Form } from "react-bootstrap";

const SntInfoText = (props) => {
  const { children } = props;
  return (
    <div {...props}>
      <div className="d-flex">
        <div style={{ minWidth: 16 }} className={`me-3`}>
          <IcomoonIcon icon="info"></IcomoonIcon>
        </div>
        <div className="flex-grow-1">
          <Form.Text>{children}</Form.Text>
        </div>
      </div>
    </div>
  );
};

export default SntInfoText;
