import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import SntCheckNarrowIcon from "@/components/Icons/SntCheckNarrowIcon";
import SntPanel from "@/components/Panel/SntPanel";
import ImportingSpinner from "@/components/ReactBootstrap/LoaderSpinner/ImportingSpinner";
import SntDialog from "@/components/SntDialog/SntDialog";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";
import SntWarning from "@/components/SntInfo/SntWarning";
import PageHeader from "@/containers/PageHeader/PageHeader";
import { useOrg } from "@/contexts/OrgContext";
import sigfoxDeviceClient from "@apis/sigfoxDeviceClient.js";
import { userSelector } from "@/redux/selectors";

const ImportTrackerSettings = ({ reload, isSystem, isPartner }) => {
  const language = useSelector((state) => state.language);
  const history = useHistory();
  const { orgId } = useOrg();
  const loginInfo = useSelector(userSelector);

  const fileRef = useRef();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState(
    language.ext_ble_sensor_no_file_selected_yet_key
  );
  const [isFileSelected, setFileSelected] = useState(false);

  const [isShowErrorDialog, setShowErrorDialog] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");

  const [noOfUpdated, setNoOfUpdated] = useState(0);
  const [uploadErrorMsg, setUploadErrorMsg] = useState(null);

  const getCurrentTrackerAdminFilterSettings = () => {
    let currentTrackerAdminFilterSettings = sessionStorage.getItem(
      "TRACKER_ADMIN"
    );
    if (currentTrackerAdminFilterSettings) {
      currentTrackerAdminFilterSettings = JSON.parse(
        currentTrackerAdminFilterSettings
      );
    } else {
      currentTrackerAdminFilterSettings = {};
    }

    // add orgid to filter devices
    if (
      currentTrackerAdminFilterSettings &&
      currentTrackerAdminFilterSettings.query &&
      orgId
    ) {
      currentTrackerAdminFilterSettings.query = currentTrackerAdminFilterSettings.query.filter(
        function (param) {
          return param.filterKey !== "organizations";
        }
      );

      if (orgId) {
        currentTrackerAdminFilterSettings.query.push({
          categoryId: "assetInfo",
          filterKey: "organizations",
          filterType: "MULTI_SELECT_AUTO_COMPLETE",
          searchType: "STATIC",
          notFilter: false,
          filterValue: {
            includeEmpty: false,
            includeNoEmpty: false,
            selectedValues: [orgId],
          },
        });
      }
    }

    currentTrackerAdminFilterSettings.startIndex = null;
    currentTrackerAdminFilterSettings.maxResults = null;
    currentTrackerAdminFilterSettings.requestedTableColumns = [
      {
        columnKey: "serial",
        order: null,
      },
    ];

    return currentTrackerAdminFilterSettings;
  };

  const onExportCsvFileClicked = (e) => {
    e.preventDefault();
    sigfoxDeviceClient.exportAsCsvFile(getCurrentTrackerAdminFilterSettings());
  };

  const onExportXlsFileClicked = (e) => {
    e.preventDefault();
    sigfoxDeviceClient.exportAsXlsFile(getCurrentTrackerAdminFilterSettings());
  };

  const onUploadClicked = (e) => {
    e.preventDefault();
    fileRef.current.click();
  };

  const onSelectedFileChange = (event) => {
    if (event.target && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
      setFileSelected(true);
    }
  };

  const cleanState = () => {
    setNoOfUpdated(0);
    setUploadErrorMsg(null);
  };

  const cleanSelectedFile = () => {
    setSelectedFile(null);
    setFileName(language.ext_ble_sensor_no_file_selected_yet_key);
    setFileSelected(false);
    if (fileRef.current) {
      fileRef.current.value = null;
    }
  };

  const onImportClick = () => {
    if (isFileSelected && selectedFile) {
      importTrackerSettings();
    } else {
      setErrorDialogMessage(language.ext_ble_sensor_no_file_selected_yet_key);
      setShowErrorDialog(true);
    }
  };

  const importTrackerSettings = () => {
    setLoading(true);
    let _dataForm = new FormData();
    _dataForm.append("file", selectedFile);
    sigfoxDeviceClient
      .importTrackerSettings(_dataForm)
      .then(({ data }) => {
        setUploadErrorMsg(null);
        if (data) {
          setNoOfUpdated(data.length || 0);
        }
      })
      .catch((error) => {
        setNoOfUpdated(0);
        if (error && error.response && error.response.data) {
          setUploadErrorMsg(error.response.data.message);
        } else {
          setErrorDialogMessage(error.message);
          setShowErrorDialog(true);
        }
      })
      .finally(() => {
        setLoading(false);
        cleanSelectedFile();
      });
  };

  const onCancelClick = () => {
    if (isSystem) {
      history.push(`/trackers`);
    } else if (isPartner) {
      history.push(`/trackers_partner`);
    } else {
      history.push(`/trackers_admin?org=${orgId}`);
    }
  };

  useEffect(() => {
    cleanState();
    cleanSelectedFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <SntDialog
        isShow={isShowErrorDialog}
        onSave={() => {
          setShowErrorDialog(false);
        }}
        btnSaveClassName="danger"
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.import_key}
      >
        {() => <div>{errorDialogMessage}</div>}
      </SntDialog>
      <PageHeader title={language.import_tracker_settings} />
      <Row>
        <Col xs="12">
          {noOfUpdated !== 0 && (
            <Col className="mb-3" xs="12">
              <Alert variant="success">
                <SntCheckNarrowIcon className="me-2" />
                <div id="upload-success">
                  {noOfUpdated !== 0 ? (
                    <div>
                      {language.number_of_tracker_settings_updated_key.replace(
                        "{0}",
                        noOfUpdated
                      )}
                    </div>
                  ) : null}
                </div>
              </Alert>
            </Col>
          )}

          {uploadErrorMsg && (
            <Col className="mb-3" xs="12">
              <SntWarning>
                <IcomoonIcon
                  icon="warning"
                  size="18"
                  className="me-2"
                ></IcomoonIcon>
                {ReactHtmlParser(uploadErrorMsg)}
              </SntWarning>
            </Col>
          )}

          <SntInfoPanel>
            {language.import_tracker_settings_help_msg_header_key}
            <ul>
              <li>{language.import_tracker_settings_help_msg_step_1_key}</li>
              <li>{language.import_tracker_settings_help_msg_step_2_key}</li>
              <li>{language.import_tracker_settings_help_msg_step_3_key}</li>
            </ul>
          </SntInfoPanel>

          <SntPanel title={language.download_template_or_export_asset_trackers}>
            <Col xs="12">
              {language.import_tracker_settings_download_step_1_key}
            </Col>
            <Col xs="12">
              <Button
                className="mt-2 me-2"
                variant="outline-sensolus"
                title={language.export_as_csv_key}
                onClick={onExportCsvFileClicked}
              >
                {language.export_as_csv_key}
              </Button>
              <Button
                className="mt-2 me-2"
                variant="outline-sensolus"
                title={language.export_as_xls_key}
                onClick={onExportXlsFileClicked}
              >
                {language.export_as_xls_key}
              </Button>
            </Col>
          </SntPanel>

          <SntPanel title={language.import_tracker_settings}>
            <SntInfoPanel>
              <div>
                {language.you_can_import_following_tracker_settings}
                <ul>
                  <li>{language.secondarySerial_key.toLowerCase()}</li>
                  <li>{language.secondary_product_key.toLowerCase()}</li>
                  <li>{language.name_key.toLowerCase()}</li>
                  <li>{language.v41_third_party_id_key.toLowerCase()}</li>
                  <li>
                    {language.search_filter_assetTags_label.toLowerCase()}
                  </li>
                  <li>{language.visibility_filters_key}</li>
                  <li>{language.decorator_color_key}</li>
                  {"PARTNER" === loginInfo.organisation.organisationType ||
                  "SYSTEM" === loginInfo.organisation.organisationType ? (
                    <li>{language.partner_tags}</li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </SntInfoPanel>
            <Col xs="12">
              <div
                title={fileName}
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "800px",
                }}
              >
                {fileName}
              </div>
            </Col>

            <Row className="mb-3">
              <Col xs="12" className="mt-2">
                <Button
                  id="upload"
                  variant="sensolus-greylight"
                  onClick={onUploadClicked}
                >
                  {isFileSelected
                    ? language.change_key
                    : language.select_file_key}
                </Button>
                <input
                  name="file"
                  type="file"
                  required
                  accept=".xls,.xlsx,.csv"
                  className="form-control"
                  style={{ display: "none" }}
                  ref={fileRef}
                  onChange={onSelectedFileChange}
                />
              </Col>
            </Row>
          </SntPanel>
          <Row className="mb-3 ms-2">
            <Col xs="4" md="3" lg="2" xl="1">
              <ImportingSpinner
                loading={loading}
                message={language.import_tracker_settings}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="12">
              <Button
                variant="sensolus"
                type="button"
                className=" me-2"
                onClick={onImportClick}
              >
                {language.import_key}
              </Button>
              <Button variant="outline-sensolus" onClick={onCancelClick}>
                {language.cancel_key}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ImportTrackerSettings;
