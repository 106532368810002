const _infraRoute = {
  page: "/infrastructures_admin",
  updatePage: "/infrastructures_update",
  createPage: "",
  getPage: (data) => {
    return `/infrastructures_admin?org=${data.orgId}`;
  },
  getUpdatePage: (data) => {
    data.org = data.org || data.orgId;
    data.type = data.type || "view";

    const queryString = new URLSearchParams(data).toString();
    return `/infrastructures_update?${queryString}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/infrastructures_update?org=${data.orgId}`;
    return `/infrastructures_update?`;
  },
};

export default _infraRoute;
