import { useState } from "react";

import ItemSelector from "./ItemSelector";
import { useSelector } from "react-redux";

const ItemLanguageSelector = (props) => {
  const language = useSelector((state) => state.language);
  let [languageList] = useState(() => {
    let languageItems = ["en", "en-gb", "fr", "nl", "de", "es"].map((key) => ({
      label: language["language_" + key],
      value: key,
    }));

    languageItems.sort(function (a, b) {
      return a.label > b.label;
    });

    return languageItems;
  });

  return <ItemSelector {...props} options={languageList}></ItemSelector>;
};

export default ItemLanguageSelector;
