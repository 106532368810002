import $ from "jquery";
import { useEffect, useState } from "react";
import { Form, Pagination, Card, Col, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { StickyContainer } from "react-sticky";

import { useDataFilterTable } from "../hooks/useDataFilterTable";
import MobileSortingDiaglog from "./MobileSortingDiaglog";

import IcomoonIcon from "../../Icons/IcomoonIcon";
import SntSortIcon from "../../Icons/SntSortIcon";
import SntActionButton from "../../ReactBootstrap/SntActionButton";
import LoaderSpinner from "../../ReactBootstrap/LoaderSpinner/LoaderSpinner";
import styled from "styled-components";
import NoDataContainer from "@/cleanup/containers/NoData/NoDataContainer";

const Row = styled.tbody`
  tr:first-child {
    border-top: 2px solid ${(props) => props.decoratorColor || "transparent"};
  }
`;

export const SntDataCard = ({
  dataFilter,
  data,
  onConfigColumn,
  loading,
  noDataText,
  isShowSelectPageSize = true,
  isFloatingFooter = true,
  isShowPaging = true,
  isShowDecoratorColor = false,
  isDisabbledGroup = true,
}) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { features } = loginInfo;

  if (!noDataText) noDataText = language.no_data_key;

  const table = useDataFilterTable(dataFilter, data, {});
  const {
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    totalItems,
    toggleSortBy,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = table;

  // floating pager
  useEffect(() => {
    if (isFloatingFooter) {
      if ($(".fl-scrolls").length < 1) {
        $(".table-container").floatingScroll();
      }
    }
    return () => {
      $(".table-container").floatingScroll("destroy");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (isFloatingFooter) {
      setTimeout(() => {
        $(".table-container").floatingScroll("update");
        let flScrollsEl = $(".fl-scrolls");
        flScrollsEl.css("margin-bottom", $(".table-footer").height() || "51px");
        $(".table-container").css(
          "margin-bottom",
          Number.parseInt($(".table-footer").height()) - 27 + "px" || "51px"
        );
      }, 10);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <StickyContainer>
      <>
        {rows.length === 0 && !loading ? (
          <NoData noDataText={noDataText} />
        ) : (
          <div style={{ marginBottom: 86 }}>
            {rows.map((row, i) => {
              prepareRow(row);
              let decoratorColor = "";
              if (isShowDecoratorColor) {
                decoratorColor =
                  row.original && row.original.decoratorColor
                    ? row.original.decoratorColor
                    : null;
              }

              return (
                <TableCard
                  key={i}
                  data={row.cells}
                  decoratorColor={decoratorColor}
                  language={language}
                />
              );
            })}
          </div>
        )}
      </>

      <div style={{ marginBottom: "5px" }}>&nbsp;</div>
      {isShowPaging && (
        <div
          className={`table-footer ${
            isFloatingFooter ? "floating-footer" : ""
          }`}
          style={{ left: 12, right: 12 }}
        >
          <div
            style={{
              padding: isFloatingFooter && "0.5rem",
              paddingBottom: isFloatingFooter && "0px",
            }}
          >
            <SntTablePaging
              dataFilter={dataFilter}
              isDisabbledGroup={isDisabbledGroup}
              features={features}
              toggleSortBy={toggleSortBy}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              pageSize={pageSize}
              setPageSize={setPageSize}
              onConfigColumn={onConfigColumn}
              isShowSelectPageSize={isShowSelectPageSize}
              totalItems={totalItems}
              headerGroups={headerGroups}
            />
          </div>
        </div>
      )}
      <LoaderSpinner loading={loading} minHeight="600px" top="300px" />
    </StickyContainer>
  );
};

const TableCard = ({ data, decoratorColor, language }) => {
  return (
    <Col xs="12" style={{ marginBottom: 12 }}>
      <Card>
        <Card.Body
          className="p-0"
          style={{ position: "relative", overflowX: "auto" }}
        >
          <Table
            striped
            borderless
            className="mb-0"
            style={{ borderRadius: 5 }}
          >
            <Row decoratorColor={decoratorColor}>
              {data.map((item, idx) => (
                <tr key={idx}>
                  <td style={{ width: "40%", minWidth: "100px" }}>
                    {item.column.isGrouped && (
                      <span style={{ fontWeight: "normal" }}>
                        {language.group_by_key}:{" "}
                      </span>
                    )}
                    <label>{item.column.render("Header")}</label>
                  </td>
                  <td>{item.render("Cell")}</td>
                </tr>
              ))}
            </Row>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

const SntTablePaging = ({
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  onConfigColumn,
  isShowSelectPageSize,
  totalItems,
  headerGroups,
  features,
  toggleSortBy,
  isDisabbledGroup,
  dataFilter,
}) => {
  const language = useSelector((state) => state.language);
  const [showMobileSorting, setShowMobileSorting] = useState(false);
  let visibleTotal = getTotalItemView(totalItems, pageSize, pageIndex);

  return (
    <>
      <div>
        <div className="d-block d-md-inline-block float-md-start pt-2 mb-2">
          {totalItems && totalItems.countByFilter > 0 ? (
            <span>
              {language.showing_key}{" "}
              <strong>
                {visibleTotal.from} - {visibleTotal.to}
                {"/"}
                {totalItems.countByFilter} {language.items_key}
              </strong>{" "}
            </span>
          ) : (
            <span>
              {language.page_key}{" "}
              <strong>
                {pageOptions.length === 0 ? 0 : pageIndex + 1} of{" "}
                {pageOptions.length}
              </strong>{" "}
            </span>
          )}
        </div>

        <div className="d-block d-md-inline-block float-md-end mb-2">
          <>
            <MobileSortingDiaglog
              headers={headerGroups[0].headers}
              show={showMobileSorting}
              setShowMobileSorting={setShowMobileSorting}
              features={features}
              toggleSortBy={toggleSortBy}
              isDisabbledGroup={isDisabbledGroup}
              dataFilter={dataFilter}
            />
            <div className="d-inline-block">
              <SntActionButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowMobileSorting(true);
                }}
                style={{ color: "#0071a1" }}
              >
                <SntSortIcon />
              </SntActionButton>

              {onConfigColumn && (
                <SntActionButton
                  onClick={(e) => {
                    e.preventDefault();
                    onConfigColumn();
                  }}
                >
                  <IcomoonIcon
                    icon="cog"
                    color="#0071a1"
                    size="16"
                  ></IcomoonIcon>
                </SntActionButton>
              )}
            </div>
          </>

          {isShowSelectPageSize && (
            <div className="d-inline-block">
              <Form.Select
                size="md"
                value={pageSize}
                className="me-3"
                style={{
                  width: "115px",
                }}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50, 100, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}

          <div className="d-inline-block float-end">
            <Pagination className="mb-0">
              <Pagination.First
                disabled={!canPreviousPage}
                onClick={() => canPreviousPage && gotoPage(0)}
              />
              <Pagination.Prev
                disabled={!canPreviousPage}
                onClick={() => canPreviousPage && previousPage()}
              />
              <Pagination.Item
                active={true}
                onClick={() => gotoPage(pageIndex)}
              >
                {pageIndex + 1}
              </Pagination.Item>
              <Pagination.Next
                disabled={!canNextPage}
                onClick={() => canNextPage && nextPage()}
              />
              <Pagination.Last
                disabled={!canNextPage}
                onClick={() => canNextPage && gotoPage(pageCount - 1)}
              />
            </Pagination>
          </div>
        </div>
      </div>
    </>
  );
};

const NoData = ({ noDataText }) => {
  return (
    <div
      className={"tr odd"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="td">
        <NoDataContainer noDataText={noDataText} />
      </div>
    </div>
  );
};

const getTotalItemView = (totalItems, pageSize, pageIndex) => {
  pageIndex++;
  if (pageIndex * pageSize - pageSize > totalItems.countByFilter) {
    pageIndex = 1;
  }
  let from =
    pageIndex * pageSize - pageSize + 1 > totalItems.countByFilter
      ? 0
      : pageIndex * pageSize - pageSize + 1;
  let to =
    pageIndex * pageSize < totalItems.countByFilter
      ? pageIndex * pageSize
      : totalItems.countByFilter;

  return { from: from, to: to };
};
