import { useEffect, useState } from "react";
import visibilityFilterClient from "../../apis/visibilityFilterClient";
import { ItemSelector } from "../ReactBootstrap/FormValidation";

const SntVisibilityFilterSelector = (props) => {
  const { orgId } = { ...props };
  const [items, setItems] = useState([]);

  useEffect(() => {
    visibilityFilterClient
      .findAll({ orgId: orgId !== 0 ? orgId : null })
      .then(({ data }) => {
        if (data.length > 0) {
          let visibilityFilters = data;
          visibilityFilters.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });

          var visibilityFiltersItems = [];
          for (let i = 0; i < visibilityFilters.length; i++) {
            visibilityFiltersItems.push({
              label: visibilityFilters[i].name,
              value: visibilityFilters[i].id,
            });
          }
          setItems(visibilityFiltersItems);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return <ItemSelector {...props} multi={true} options={items} />;
};

export default SntVisibilityFilterSelector;
