import { Card } from "react-bootstrap";
import SntCloseIcon from "@icons/SntCloseIcon.js";

const SntPanel = ({
  title,
  titleRender,
  rightTitle,
  rightRender,
  className = "",
  bodyClassName = "",
  children,
  onClick,
  style,
  hasCloseButton = false,
  onCloseAction,
}) => {
  return (
    <Card
      className={`w-100 mb-3 ${className}`}
      onClick={onClick}
      style={{ ...style }}
    >
      {(title || rightTitle || titleRender || rightRender) && (
        <Card.Header className="bg-sensolus-lightblue">
          <div className="justify-content-between d-flex">
            {title && (
              <div className="align-self-center fw-bold text-sensolus">
                {title}
              </div>
            )}
            {!title && titleRender && <> {titleRender()}</>}
            {rightTitle && (
              <div className="align-self-center">
                <div className="float-end">{rightTitle}</div>
              </div>
            )}
            {!rightTitle && rightRender && (
              <div className="align-self-center">
                <div className="float-end">{rightRender()}</div>
              </div>
            )}
            {hasCloseButton && (
              <div className="align-self-center">
                <SntCloseIcon
                  className="float-end"
                  onClick={() => {
                    onCloseAction();
                  }}
                />
              </div>
            )}
          </div>
        </Card.Header>
      )}
      <Card.Body className={bodyClassName}>{children}</Card.Body>
    </Card>
  );
};

export default SntPanel;
