import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import StringUtils from "@/utils/StringUtils.js";

export const LoginButtonPreview = ({
  buttonImageSrc,
  loginButtonBackgroundColor,
  loginButtonBorderColor,
  loginButtonTextColor,
  loginButtonText,
}) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const customInterface = useSelector((state) => state.customInterface);
  const { organisation } = loginInfo;
  return (
    <div>
      <Button
        title={StringUtils.replaceStr(
          language.login_with_your_company_account_key,
          organisation.name
        )}
        className="text-truncate"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          width: "100px",
          padding: "4px",
          fontSize: "4px",
          fontWeight: "100",
          borderRadius: "4px",
          backgroundColor: loginButtonBackgroundColor
            ? loginButtonBackgroundColor
            : customInterface.loginButtonColor
            ? customInterface.loginButtonColor
            : "#304269",
          border: `1px solid ${
            loginButtonBorderColor
              ? loginButtonBorderColor
              : customInterface.loginButtonColor
              ? customInterface.loginButtonColor
              : "#304269"
          }`,
          color: loginButtonTextColor ? loginButtonTextColor : "#fff",
          textDecoration: "none",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
          cursor: "pointer",
        }}
      >
        {buttonImageSrc && (
          <img
            src={buttonImageSrc}
            alt=""
            style={{ width: "10px", height: "10px" }}
          />
        )}
        {loginButtonText
          ? loginButtonText
          : StringUtils.replaceStr(
              language.login_with_your_company_account_key,
              organisation.name
            )}
      </Button>
    </div>
  );
};
