import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import "./sntcolorpicker.css";
import styled from "styled-components";

const Styles = styled.div`
  .rc-color-picker-trigger {
    width: 27px;
    background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(255, 17, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

// @see http://react-component.github.io/color-picker/examples/simple.html
const SntColorPicker = ({ name, hexColor, onChange }) => {
  const [color, setColor] = useState(hexColor);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setColorValue(hexColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexColor]);

  function setColorValue(color) {
    setFieldValue(name, color);
    setColor(color);
  }

  function changeHandler(values) {
    setColorValue(values.color);
    onChange && onChange(values);
  }

  return (
    <>
      {color ? (
        <ColorPicker
          color={color}
          defaultColor="#FFFFFF"
          enableAlpha={false}
          onChange={changeHandler}
          placement="topLeft"
        />
      ) : (
        <Styles>
          <ColorPicker
            color={color}
            defaultColor="#FFFFFF"
            enableAlpha={false}
            onChange={changeHandler}
            placement="topLeft"
          />
        </Styles>
      )}
    </>
  );
};

export default SntColorPicker;
