import { useEffect } from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";

const SntInputTextField = ({
  name,
  placeholder = "",
  disabled = false,
  type = "text",
  className = "",
  autocomplete = "new-password",
  required,
  handleChange,
  rightIcon, //<i></i>
}) => {
  const [field, meta] = useField(name);

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  return (
    <div className={`position-relative ${rightIcon && "input-custom"}`}>
      <Form.Control
        className={`${className} ${formGroupClass}`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        value={field.value || ""}
        autoComplete={autocomplete}
        required={required}
        style={{ position: "relative" }}
      />
      {rightIcon && <span className="right-input-icon">{rightIcon}</span>}
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </div>
  );
};

export default SntInputTextField;
