const _deviceHomeRoute = {
  page: "/tracked_assets",
  updatePage: "/tracked_assets_update",
  createPage: "",
  getPage: (data) => {
    return `/tracked_assets`;
  },
  getUpdatePage: (data) => {
    const queryString = new URLSearchParams(data).toString();
    return `/tracked_assets_update?${queryString}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/tracked_assets_update?org=${data.orgId}`;
    return `/tracked_assets_update?`;
  },
};

export default _deviceHomeRoute;
