import { useMemo } from "react";
import { useSelector } from "react-redux";
import SntTabRouteContainer from "../../components/SntTab/SntTabRouteContainer";
import { useRoutes } from "@/components/SntTab/useRoutes.js";
import PageHeader from "../../containers/PageHeader/PageHeader";
import SingleSignOnTabIds from "./SingleSignOnTabIds.js";
import PagePermissionWarning from "@/containers/AdminLayout/PagePermissionWarning/PagePermissionWarning";
import SSODomainVerification from "@pages/SingleSignOn/SSODomainVerification.js";
import FirstSingleSignOn from "@pages/SingleSignOn/FirstSingleSignOn.js";
import SecondSingleSignOn from "@pages/SingleSignOn/SecondSingleSignOn.js";

const pageName = "SSO";

const SSOSetting = ({ reload }) => {
  const language = useSelector((state) => state.language);
  const route = {
    route: "single_sign_on?",
    tabActive: SingleSignOnTabIds.domain_verification_tab,
  };

  const { routeParams } = useRoutes(route);

  const allTabs = useMemo(
    () => [
      {
        id: SingleSignOnTabIds.domain_verification_tab,
        label: language.domain_verification_key,
        render: true,
        component: () => <SSODomainVerification reload={reload} />,
      },
      {
        id: SingleSignOnTabIds.identity_provider_1_tab,
        label: language.identity_provider_1_key,
        render: true,
        component: () => <FirstSingleSignOn reload={reload} />,
      },
      {
        id: SingleSignOnTabIds.identity_provider_2_tab,
        label: language.identity_provider_2_key,
        render: true,
        component: () => <SecondSingleSignOn reload={reload} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reload]
  );

  return (
    <>
      <PagePermissionWarning pageName={pageName} />

      <PageHeader
        title={language.single_sign_on_key}
        tabTitle={language.single_sign_on_key}
      />

      <SntTabRouteContainer
        allTabs={allTabs}
        tabGroupInfo={{
          route: route.route,
          tabActive: routeParams.tabActive,
        }}
        browserTabTitle={language.single_sign_on_key}
      />
    </>
  );
};

export default SSOSetting;
