import SntIcon from "./SntIcon";

const SntFilterIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M1 0H14.7733V1.53037H14.7121L9.41703 6.82544V16L6.35629 12.9393V6.81779L1.06887 1.53037H1V0Z" />
    </SntIcon>
  );
};

export default SntFilterIcon;
