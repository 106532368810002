import { useEffect, useState } from "react";
import { apiClient } from "./apiClient";
import { useSelector } from "react-redux";

export default {
  findAll(params) {
    let options = params && params.orgId ? "?orgId=" + params.orgId : "";
    if (params && params.orgId && params && params.isAllowGetAll) {
      options += "&isAllowGetAll=true";
    }
    return apiClient.get(`/rest/organisationFilter${options}`);
  },

  getById(id) {
    return apiClient.get(`/rest/organisationFilter/${id}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/organisationFilter/${id}`);
  },

  update(id, body) {
    return apiClient.put(`/rest/organisationFilter/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  create(body) {
    return apiClient.post(`/rest/organisationFilter`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  check(id) {
    return apiClient.get(`/rest/organisationFilter/checkVisibility/${id}`);
  },

  getOrgVisibilityFilter(orgId) {
    return apiClient.get(
      `/rest/organisationFilter${orgId ? "?orgId=" + orgId : ""}`
    );
  },
};

export const useGetOrgVisibilityFilter = (orgId) => {
  const loginInfo = useSelector((state) => state.user);
  let { features } = loginInfo;
  const [orgVisibilityFilters, setOrgVisibilityFilters] = useState([]);
  useEffect(() => {
    features.organisation_visibility_filters &&
      apiClient
        .get(`/rest/organisationFilter${orgId ? "?orgId=" + orgId : ""}`)
        .then(({ data }) => {
          let list = [];
          for (let i = 0; i < data.length; i++) {
            list.push({
              value: data[i].id,
              label: data[i].name,
            });
          }
          setOrgVisibilityFilters(list);
        });
  }, [orgId, features.organisation_visibility_filters]);
  return orgVisibilityFilters;
};
