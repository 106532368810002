import { apiClient } from "./apiClient";

export default {
  get(id) {
    return apiClient.get(`/rest/metarWeatherLocation/${id}`);
  },

  create(params) {
    return apiClient.post(`/rest/metarWeatherLocation`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  update(id, params) {
    return apiClient.put(`/rest/metarWeatherLocation/${id}`, params, {
      headers: { "content-type": "application/json" },
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/metarWeatherLocation/${id}`);
  },
};
