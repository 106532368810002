import { apiClient } from "./apiClient";

export default {
  getByOrg(params, cancelToken) {
    return apiClient.get("/rest/roles", {
      params: params,
      cancelToken: cancelToken,
    });
  },

  getAssignableRoles(orgId) {
    return apiClient.get(`/rest/roles/getAssignableRoles?orgId=${orgId}`);
  },

  search(params) {
    return apiClient.post(`/rest/roles/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/roles/${id}`);
  },
  findOne(id) {
    return apiClient.get(`/rest/roles/${id}`);
  },

  updateRole(id, params) {
    return apiClient.put(`/rest/roles/${id}`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  createRole(params) {
    return apiClient.post(`/rest/roles`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
