import { useEffect, useState } from "react";
import roleClient from "../../apis/roleClient";
import { ItemSelector } from "../ReactBootstrap/FormValidation";

const SntRoleSelector = (props) => {
  const [items, setItems] = useState([]);
  const { orgId } = { ...props };

  useEffect(() => {
    roleClient
      .getAssignableRoles(orgId !== 0 ? orgId : null)
      .then(({ data }) => {
        if (data.count > 0) {
          let roles = data.data;
          roles.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });

          var roleSelectionItems = [];
          for (let i = 0; i < roles.length; i++) {
            roleSelectionItems.push({
              label: roles[i].name,
              value: roles[i].id,
            });
          }
          setItems(roleSelectionItems);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return <ItemSelector {...props} options={items}></ItemSelector>;
};

export default SntRoleSelector;
