import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
const MismatchUpdateDialog = ({
  isShow,
  onSave,
  onKeepCurrentTowerLocation,
  onClose,
  children,
  title,
  saveTxt,
  closeTxt,
  minBodyHeight,
  btnSaveClassName = "sensolus",
  isShowCloseButton = true,
  isShowSaveButton = true,
  refWrapper,
  backdrop = true,
  customModalClassname = "",
  bodyClass,
  onHide,
  size = "lg", // 'sm' | 'lg' | 'xl'
  disabled = false,
}) => {
  const language = useSelector((state) => state.language);
  
  function onSaveClicked() {
    onSave && onSave();
  }

  function onCloseClicked() {
    onClose && onClose();
  }
  
  const onHideClicked = () => {
    if (onHide) {
      onHide();
    } else {
      onClose && onClose();
    }
  };

  return (
    <Modal
      show={isShow}
      dialogClassName="modal-dialog-scrollable"
      onHide={onHideClicked}
      size={size}
      backdrop={backdrop}
      className={`${customModalClassname}`}
    >
      <div ref={refWrapper} className="modal-content">
        <Modal.Header closeButton={isShowCloseButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className={`${bodyClass || ""} snt-scroll`}
          style={{ minHeight: minBodyHeight }}
        >
          {isShow && children ? children() : <></>}
        </Modal.Body>

        {(isShowSaveButton || isShowCloseButton) && (
          <Modal.Footer className="d-flex justify-content-start">
            {isShowSaveButton && (
              <Button
                variant={btnSaveClassName}
                onClick={onSaveClicked}
                disabled={disabled}
              >
                {saveTxt || "Save"}
              </Button>
            )}
            <Button
              variant={"outline-sensolus"}
              onClick={onKeepCurrentTowerLocation}
              disabled={disabled}
            >
              {language.keep_current_tower_location}
            </Button>
            {isShowCloseButton && (
              <Button variant="outline-sensolus" onClick={onCloseClicked}>
                {closeTxt || "Close"}
              </Button>
            )}
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};

export default MismatchUpdateDialog;
