import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormGroupLayout,
  InputCheck,
  ItemSelector,
} from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import ServiceInput from "../Utils/ServiceInput.js";
import { Col } from "react-bootstrap";
import SntInfoPanel from "../../../components/SntInfo/SntInfoPanel.js";
import HtmlParser from "react-html-parser/lib/HtmlParser";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";

const IBeaconScanServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();

  const scanMode = [
    { value: "ANCHOR", label: language.zone_anchor_mode },
    { value: "ANCHOR_NEWSTYLE", label: language.zone_anchor_newstyle_mode },
    { value: "CARRY", label: language.carry_beacons },
    { value: "INVENTORY", label: language.inventory_mode },
  ];

  const [service, setService] = useState({
    type: "IBEACON_SCAN",
    active: true,
    configuration: {
      scanMode: { value: "ANCHOR" },
      zoneAnchorMode: false,
      carryBeacons: false,
      rssiFilter: -150,
      minDistanceInventoryMode: 30,
      minimumDistanceFromStopState: 1500,
      minimumDistanceToStealBeaconInEnterState: 500,
      onTheMoveIntervalCarriedBeacons: 20,
      minimumSpeedToBeCarried: 0,
      minimumObservationsToBeCarried: 1,
      carryModeRssiThresholdForPreciseInventory: 0,
      carryModeMinDistanceCoarseRelocate: 500,
      carryModeRssiThresholdForCoarseRelocate: -80,
      carryBeaconsOnStop: false,
    },
  });

  useEffect(() => {
    if (selectedService && selectedService.configuration && isShow) {
      if (selectedService.configuration.zoneAnchorMode) {
        selectedService.configuration.scanMode = { value: "ANCHOR" };
      } else if (selectedService.configuration.carryBeacons) {
        selectedService.configuration.scanMode = { value: "CARRY" };
      } else {
        selectedService.configuration.scanMode = { value: "INVENTORY" };
      }

      setService({ ...service, ...selectedService });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  const onSubmit = (values) => {
    onSave({
      ...service,
      configuration: {
        zoneAnchorMode: values.scanMode.value === "ANCHOR",
        carryBeacons: values.scanMode.value === "CARRY",
        rssiFilter: values.rssiFilter,
        minDistanceInventoryMode: values.minDistanceInventoryMode,
        minimumDistanceFromStopState: values.minimumDistanceFromStopState,
        minimumDistanceToStealBeaconInEnterState:
          values.minimumDistanceToStealBeaconInEnterState,
        onTheMoveIntervalCarriedBeacons: values.onTheMoveIntervalCarriedBeacons,
        minimumSpeedToBeCarried: values.minimumSpeedToBeCarried,
        minimumObservationsToBeCarried: values.minimumObservationsToBeCarried,
        carryModeRssiThresholdForPreciseInventory:
          values.carryModeRssiThresholdForPreciseInventory,
        carryModeMinDistanceCoarseRelocate:
          values.carryModeMinDistanceCoarseRelocate,
        carryModeRssiThresholdForCoarseRelocate:
          values.carryModeRssiThresholdForCoarseRelocate,
        carryBeaconsOnStop: values.carryBeaconsOnStop,
      },
    });
    setIsShow(false);
  };

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => formikRefs.current.handleSubmit()}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <Formik
          initialValues={service.configuration}
          innerRef={formikRefs}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          <Form className="form-horizontal">
            <SntInfoPanel>
              {HtmlParser(language.ibeacon_mode_explanation)}
            </SntInfoPanel>

            <FormGroupLayout
              xl="7"
              label={language.scan_mode_key}
              rightRender={() => (
                <Col xs="12" lg="6">
                  <ItemSelector name="scanMode" options={scanMode} />
                </Col>
              )}
            />
            <ServiceConfigPanel title="Common parameters">
              {() => (
                <div>
                  <FormGroupLayout
                    xl="7"
                    label={language.rssi_filter}
                    rightRender={() => (
                      <ServiceInput
                        name="rssiFilter"
                        inputType={"number"}
                        placeholder={language.rssi_filter}
                      />
                    )}
                  />
                </div>
              )}
            </ServiceConfigPanel>
            <ServiceConfigPanel title="Inventory mode parameters">
              {() => (
                <div>
                  <FormGroupLayout
                    xl="7"
                    label={language.minDistanceInventoryMode}
                    rightRender={() => (
                      <ServiceInput
                        name="minDistanceInventoryMode"
                        inputType={"number"}
                        placeholder={language.minDistanceInventoryMode}
                      />
                    )}
                  />
                </div>
              )}
            </ServiceConfigPanel>
            <ServiceConfigPanel title="Carry mode parameters">
              {() => (
                <div>
                  <SntInfoPanel>
                    {HtmlParser(language.carry_mode_explanation)}
                  </SntInfoPanel>

                  <FormGroupLayout
                    xl="7"
                    label={language.carryBeaconsOnStop}
                    rightRender={() => (
                      <InputCheck
                        name="carryBeaconsOnStop"
                        placeholder={language.carryBeaconsOnStop}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xl="7"
                    label={language.minimumSpeedToBeCarried}
                    rightRender={() => (
                      <ServiceInput
                        name="minimumSpeedToBeCarried"
                        inputType={"number"}
                        placeholder={language.minimumSpeedToBeCarried}
                      />
                    )}
                  />
                  <FormGroupLayout
                    xl="7"
                    label={language.minimumObservationsToBeCarried}
                    rightRender={() => (
                      <ServiceInput
                        name="minimumObservationsToBeCarried"
                        inputType={"number"}
                        placeholder={language.minimumObservationsToBeCarried}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xl="7"
                    label={language.minimumDistanceFromStopState}
                    rightRender={() => (
                      <ServiceInput
                        name="minimumDistanceFromStopState"
                        inputType={"number"}
                        placeholder={language.minimumDistanceFromStopState}
                      />
                    )}
                  />
                  <FormGroupLayout
                    xl="7"
                    label={language.minimumDistanceToStealBeaconInEnterState}
                    rightRender={() => (
                      <ServiceInput
                        name="minimumDistanceToStealBeaconInEnterState"
                        inputType={"number"}
                        placeholder={
                          language.minimumDistanceToStealBeaconInEnterState
                        }
                      />
                    )}
                  />
                  <FormGroupLayout
                    xl="7"
                    label={language.onTheMoveIntervalCarriedBeacons}
                    rightRender={() => (
                      <ServiceInput
                        name="onTheMoveIntervalCarriedBeacons"
                        inputType={"number"}
                        placeholder={language.onTheMoveIntervalCarriedBeacons}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xl="7"
                    label={language.carryModeRssiThresholdForPreciseInventory}
                    rightRender={() => (
                      <ServiceInput
                        name="carryModeRssiThresholdForPreciseInventory"
                        inputType={"number"}
                        placeholder={
                          language.carryModeRssiThresholdForPreciseInventory
                        }
                      />
                    )}
                  />
                  <FormGroupLayout
                    xl="7"
                    label={language.carryModeMinDistanceCoarseRelocate}
                    rightRender={() => (
                      <ServiceInput
                        name="carryModeMinDistanceCoarseRelocate"
                        inputType={"number"}
                        placeholder={
                          language.carryModeMinDistanceCoarseRelocate
                        }
                      />
                    )}
                  />
                  <FormGroupLayout
                    xl="7"
                    label={language.carryModeRssiThresholdForCoarseRelocation}
                    rightRender={() => (
                      <ServiceInput
                        name="carryModeRssiThresholdForCoarseRelocate"
                        inputType={"number"}
                        placeholder={
                          language.carryModeRssiThresholdForCoarseRelocate
                        }
                      />
                    )}
                  />
                </div>
              )}
            </ServiceConfigPanel>
          </Form>
        </Formik>
      )}
    </SntDialog>
  );
};

export default IBeaconScanServiceConfig;
