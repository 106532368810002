import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import wifiCellularMismatchClient from "@/apis/wifiCellularMismatchClient";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import SntLink from "@/components/ReactBootstrap/SntLink";
import SntText from "@/components/ReactBootstrap/SntText";
import { DefaultTag } from "@/components/ReactBootstrap/TagMore";
import convert from "convert-units";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import ActionToolEnum from "../../containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "../../containers/AdminLayout/Toolbar/ActionToolType";
import UpdateMismatchPopup from "./UpdateMismatchPopup";
import useDeleteAllDialog from "@pages/WifiCellularMismatch/useDeleteAllDialog.js";
import searchUIClient from "@/apis/searchUIClient";
import { DeviceHomeRoute } from "@/cleanup/utils/routesUtil/routesUtil";
import SntFilterIcon from "@/components/Icons/SntFilterIcon";
import SntBulkIcon from "@/components/Icons/SntBulkIcon";
import { Button } from "react-bootstrap";

const PAGE_NAME = "WIFI_CELLULAR_MISMATCH";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "WIFI_CELLULAR_MISMATCH",
  filtersVisibleAtInit: ["deviceSerial", "cellId", "createdAt"],
  optionsSettings: {},
};

const LocationMismatch = ({ reload }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [reloadSessionState, setReloadSessionState] = useState(false);
  const loginInfo = useSelector((state) => state.user);
  let { unitDistance, permissionsMap } = loginInfo;

  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);
  const [showResolveAll, setShowResolveAll] = useState(false);

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(
    () => [
      {
        key: "cellId",
        title: language.cell_id,
        Cell: ({ cell, value }) => {
          return <SntText value={value}></SntText>;
        },
      },
      {
        key: "deviceSerial",
        title: language.serial_key,
        Cell: ({ cell, value }) => {
          return value ? (
            <SntLink
              to={DeviceHomeRoute.getUpdatePage({
                serial: value,
              })}
            >
              {value}
            </SntLink>
          ) : (
            ""
          );
        },
      },
      {
        key: "wifiProvider",
        title: language.provider_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "macs",
        title: language.wifi_mac_addresses_key,
        Cell: ({ cell, value }) => {
          return (
            value?.map((m) => (
              <DefaultTag key={m} tagName={m} className="me-1 mb-1" />
            )) || ""
          );
        },
      },
      {
        key: "wifiLocation",
        title: language.journey_wifi_location_key,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.wifiLat && full.wifiLng ? (
            <>
              <div className="d-flex">
                latitude: {full.wifiLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.wifiLat +
                    "," +
                    full.wifiLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.wifiLng}</div>
            </>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.wifiLat != undefined && data.wifiLat != null) {
            text += data.wifiLat + " - ";
          }
          if (data.wifiLng != undefined && data.wifiLng != null) {
            text += data.wifiLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.wifiLat != undefined && data.wifiLat != null) {
            text += data.wifiLat + " - ";
          }
          if (data.wifiLng != undefined && data.wifiLng != null) {
            text += data.wifiLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "cellularInfo",
        title: language.cellular_tower_info,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          var info =
            "mcc: " +
            (full.mcc || language.unknown_key) +
            ", mnc: " +
            (full.net || language.unknown_key) +
            ", area: " +
            (full.area || language.unknown_key) +
            ", cell: " +
            (full.cellId || language.unknown_key) +
            ", network: " +
            (full.network || language.unknown_key) +
            ", country: " +
            (full.country || language.unknown_key);
          return <div>{info}</div>;
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.cellLat != undefined && data.cellLat != null) {
            text += data.cellLat + " - ";
          }
          if (data.cellLng != undefined && data.cellLng != null) {
            text += data.cellLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.cellLat != undefined && data.cellLat != null) {
            text += data.cellLat + " - ";
          }
          if (data.cellLng != undefined && data.cellLng != null) {
            text += data.cellLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "cellularLocation",
        title: language.cellular_tower_location,
        disableSortBy: true,
        Cell: ({ cell, value }) => {
          var full = cell.row.original;
          return full.cellLat && full.cellLng ? (
            <>
              <div className="d-flex">
                latitude: {full.cellLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.cellLat +
                    "," +
                    full.cellLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.cellLng}</div>
            </>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          var text = "";
          if (data.cellLat != undefined && data.cellLat != null) {
            text += data.cellLat + " - ";
          }
          if (data.cellLng != undefined && data.cellLng != null) {
            text += data.cellLng + " - ";
          }
          return [text];
        },
        cellFilterValue: (data) => {
          var text = "";
          if (data.cellLat != undefined && data.cellLat != null) {
            text += data.cellLat + " - ";
          }
          if (data.cellLng != undefined && data.cellLng != null) {
            text += data.cellLng + " - ";
          }
          return [text];
        },
      },
      {
        key: "distance",
        title: language.distance_key,
        Cell: ({ cell, value }) => {
          if (value) {
            if (unitDistance === "KILOMETER") {
              return (
                Math.round(convert(value).from("m").to("km")) +
                " " +
                language.maintenance_unit_KM_key
              );
            } else {
              return (
                Math.round(convert(value).from("m").to("mi")) +
                " " +
                language.maintenance_unit_MI_key
              );
            }
          } else {
            return "";
          }
        },
      },
      {
        key: "createdAt",
        title: language.created_date_key,
        Cell: ({ value }) => {
          return (
            <SntDateView
              value={value}
              other={language.na_key}
              format="lll"
            ></SntDateView>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const defaultColumnInit = useMemo(
    () => [
      "deviceSerial",
      "macs",
      "wifiLocation",
      "cellularLocation",
      "distance",
      "createdAt",
    ],
    []
  );

  const extraCols = useMemo(
    () => [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          var full = cell.row.original;
          return (
            <div>
              {permissionsMap["CELL_TOWER_TROUBLESHOOTING_MANAGE"] && (
                <>
                  <SntActionButton
                    title={language.add_as_filterkey}
                    onClick={(e) => {
                      onFilterClicked(full);
                    }}
                  >
                    <SntFilterIcon />
                  </SntActionButton>

                  <SntActionButton
                    title={language.edit_key}
                    onClick={(e) => {
                      setShowUpdatePopup(true);
                      setSelectedItems([full.id]);
                    }}
                  >
                    <SntPencilIcon />
                  </SntActionButton>

                  <SntActionButton
                    title={language.delete_key}
                    onClick={(e) => onDeleteClicked(e, full)}
                  >
                    <SntDeleteIcon />
                  </SntActionButton>
                </>
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      searchUIClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
          checkToShowResolveAllButton();
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteAllDialog({
    onDelete: (itemSelected, deleteAll) => {
      wifiCellularMismatchClient
        .delete(itemSelected.id, deleteAll)
        .then(() => {
          handleClose();
          table.current.resetDefault();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    allTitle: language.delete_all_mismatches_key,
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  const onUpdateDone = () => {
    table.current.refresh();
  };

  const onFilterClicked = (item) => {
    var oldOptionsSettings = sessionStorage.getItem(PAGE_NAME);
    if (oldOptionsSettings) {
      oldOptionsSettings = JSON.parse(oldOptionsSettings);
    }
    oldOptionsSettings.query = [
      {
        categoryId: "info",
        filterKey: "cellId",
        filterType: "MULTI_STRING",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          searchText: item.cellId,
        },
      },
      {
        categoryId: "info",
        filterKey: "mcc",
        filterType: "MULTI_STRING",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          searchText: item.mcc,
        },
      },
      {
        categoryId: "info",
        filterKey: "net",
        filterType: "MULTI_STRING",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          searchText: item.net,
        },
      },
      {
        categoryId: "info",
        filterKey: "area",
        filterType: "MULTI_STRING",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          searchText: item.area,
        },
      },
    ];
    sessionStorage.setItem(PAGE_NAME, JSON.stringify(oldOptionsSettings));
    setReloadSessionState((pre) => !pre);
  };

  const checkToShowResolveAllButton = () => {
    //Check if 4 filter cellId,mcc,net existed and have filterValue -> show resolve all button
    var oldOptionsSettings = sessionStorage.getItem(PAGE_NAME);
    if (oldOptionsSettings) {
      oldOptionsSettings = JSON.parse(oldOptionsSettings);
    }
    let listFilters = [];
    for (let i = 0; i <= oldOptionsSettings.query.length; i++) {
      if (
        oldOptionsSettings.query[i]?.filterKey == "cellId" ||
        oldOptionsSettings.query[i]?.filterKey == "mcc" ||
        oldOptionsSettings.query[i]?.filterKey == "net" ||
        oldOptionsSettings.query[i]?.filterKey == "area"
      ) {
        listFilters.push(oldOptionsSettings.query[i]);
      }
    }
    var countFilterValue = 0;
    if (listFilters.length === 4) {
      for (let i = 0; i <= listFilters.length; i++) {
        if (listFilters[i]?.filterValue) {
          countFilterValue++;
        }
      }
      if (countFilterValue == 4) {
        setShowResolveAll(true);
        return;
      }
    }
    setShowResolveAll(false);
  };

  const onResolveAllClick = () => {
    var optionsSettings = sessionStorage.getItem(PAGE_NAME);
    if (optionsSettings) {
      optionsSettings = JSON.parse(optionsSettings);
    }

    let fullFilter = {
      query: optionsSettings.query,
      queryObjectType: "WIFI_CELLULAR_MISMATCH",
    };
    fullFilter.startIndex = null;
    fullFilter.maxResults = null;
    fullFilter.requestedTableColumns = [
      {
        columnKey: "id",
        order: null,
      },
    ];

    searchUIClient
      .search(fullFilter)
      .then(({ data }) => {
        var listMismatch = data.data;
        var results = [];
        for (var i = 0; i < listMismatch.length; i++) {
          results.push(listMismatch[i].id);
        }
        setSelectedItems(results);
        setShowUpdatePopup(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <DeleteDialog />

      <UpdateMismatchPopup
        selectedItems={selectedItems}
        onClose={() => setShowUpdatePopup(false)}
        isShow={showUpdatePopup}
        setIsShow={setShowUpdatePopup}
        onDone={() => onUpdateDone()}
      />

      <AdminLayout
        isShowHeader={false}
        browserTabTitle={
          language.UserPermission_MENU_ADMIN_CELL_TOWER_TROUBLESHOOTING_description_key
        }
        reloadSessionState={reloadSessionState}
        reload={reload}
        pageName={PAGE_NAME}
        sharedKey={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        totalLabel={language.items_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <>
              {showResolveAll ? (
                <div className="d-flex justify-content-end">
                  <Button
                    className="mb-2"
                    variant="sensolus-greylight"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onResolveAllClick();
                    }}
                  >
                    <SntBulkIcon />
                    <span className="ms-1 d-none d-lg-inline-block">
                      {"Resolve all"}
                    </span>
                  </Button>
                </div>
              ) : (
                ""
              )}

              <SntAdvancedSearchTableViewCard
                shared={(child) => (table.current = child)}
                allColumns={allColumns}
                extraCols={extraCols}
                visibleCols={defaultColumnInit}
                disabled={isDisabled}
                onChange={(data) => {
                  onDataRequestChange(data, onChangeChildren);
                }}
                dataOptions={dataOptions}
                isDisabledCopyData={false}
                isDisabbledAddFilter={false}
                onFilterClicked={(action, key, filterKeys, query) => {
                  setForceFilterAction({ action, key, filterKeys, query });
                }}
                filterMap={filterMap}
              />
            </>
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default LocationMismatch;
